import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import { connect, useDispatch, useSelector } from 'react-redux';
import pt from 'date-fns/locale/pt-BR';
import {format, startOfDay, isBefore, startOfToday} from 'date-fns';
import * as $ from 'jquery';

import SideBar from '../../components/SideBar';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import api from '../../services/api';
import config from '../../services/config';
import { Link } from 'react-router-dom';

function AnuncioConsulta({history}) {

    const date = startOfDay(new Date());
    const [entidade, setEntidade] = useState(null); 
    const [lista, setLista] = useState(null);
    const [municipios, setMunicipios] = useState([]);
    const [tiposentidades, setTiposEntidades] = useState([]);
    const [desstatus, setDesStatus] = useState('');
    const [filtro, setFiltro] = useState({
        nome: '',        
        sigla: '',
        nome_entidade_pai: '',
        id_municipio: '',
        id_tipo_entidade: '',
        status: '',
    })


    function toggleDrop(e) {
        e.preventDefault();
        return false;
    }

    useEffect(() => {
        async function carregaTabela(){
            const result = await api.get('/entidade', {params : filtro});
            setLista(result.data ? result.data : null);

            const resultMunicipios = await api.get('/municipio');
            setMunicipios(resultMunicipios.data ? resultMunicipios.data : null);

            const resultTipos = await api.get('/tipo_entidade');
            setTiposEntidades(resultTipos.data ? resultTipos.data : null);
        }

        carregaTabela();
    }, [])

    const consutlar = async (e) => {
        e.preventDefault();
        const result = await api.get('/entidade', {params : filtro});
        setLista(result.data ? result.data : null);
    }
        
    function viewItem(e, item){
        e.preventDefault();
        history.push('/entidades_view', {registro: item});
    }

    function editItem(e, item){
        e.preventDefault();
        history.push('/entidades_cad', {registro: item});
    }

    async function confirmStatus(){
        const novostatus = entidade.status === 1 ? 0 : 1;
        const desstatus = novostatus === 1 ? 'Desativado' : 'Ativado';

        const del = await api.put('/entidade/status', {id_entidade: entidade.id_entidade, status: novostatus});
        if (del.data)
        {
            toast.success(`Entidade ${desstatus} com sucesso`);
        }

        const result = await api.get('/entidade', {params : filtro});
        setLista(result.data);
        window.$('#modalConfirm').modal('toggle');

    }

    function statusSwitch(item){

        const novostatus = item.status === 1 ? 0 : 1;
        const desstatus = novostatus === 1 ? 'DESATIVAR' : 'ATIVAR';
        setDesStatus(desstatus);
        setEntidade(item);
    }

  
    
    return (
        <>
            <NavBar history={history} />
            <SideBar opcao="entidades"/>
            <div className="content-wrapper">            
                <section className="content main-content">
                    <div className="container-fluid">
                        <br />
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">Pesquisar Entidade</li>
                            </ol>
                        </nav>
                        <div className="card">
                            <div className="card-body">
                                <form>
                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <label>Entidade Pai</label>
                                            <input type="text" className="form-control" value={filtro.nome_entidade_pai} onChange={e => setFiltro({...filtro, nome_entidade_pai: e.target.value})}/>
                                        </div>  
                                    </div>  
                                    <div className="form-row">                                  
                                        <div className="form-group col-md-3">
                                            <label>Município</label>
                                            <select className="form-control" onChange={e => setFiltro({...filtro, id_municipio: e.target.value})} value={filtro.id_municipio}>
                                                <option value="" key="todos">Todos</option>
                                                {municipios.map((item, index) => 
                                                    <option key={index} value={item.id_municipio}>{item.nome}</option>
                                                )}
                                            </select>
                                        </div>

                                        <div className="form-group col-md-3">
                                            <label>Tipo</label>
                                            <select className="form-control" onChange={e => setFiltro({...filtro, id_tipo_entidade: e.target.value})} value={filtro.id_tipo_entidade}>
                                                <option value="" key="todos">Todos</option>
                                                {tiposentidades.map((item, index) => (
                                                    <option key={index} value={item.id_tipo_entidade}>{item.descricao}</option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="form-group col-md-3">
                                            <label>Status</label>
                                            <select className="form-control" onChange={e => setFiltro({...filtro, status: e.target.value})} value={filtro.status}>
                                                <option value="">Todos</option>
                                                <option value="0">Ativo</option>
                                                <option value="1">Desativado</option>
                                            </select>
                                        </div>
                                        <div className={'form-group col-md-3 align-items-end d-flex flex-column'} >
                                            <button className="btn btn-primary mb-1 col-md-6" onClick={consutlar}>Consultar</button>
                                            <Link className="btn btn-primary col-md-6" to="/entidades_cad">Novo</Link>
                                        </div> 
                                        
                                    </div>
                                                                       
                                </form>
                            
                                <hr />
                                <table className="table table-striped">
                                    <thead >
                                        <tr>
                                            <th style={{width: 180}} >NOME</th>
                                            <th style={{width: 40}} >SIGLA</th>
                                            <th>NOME RESPONSÁVEL</th>
                                            <th>CARGO RESPONSÁVEL</th>
                                            <th>ENTIDADE PAI</th>
                                            <th>MUNICÍPIO</th>
                                            <th>TIPO</th>
                                            <th>STATUS</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {lista ? lista.map((item, index) => (
                                            <tr key={index}>
                                                <th>{item.nome}</th>
                                                <th className="text-center">{item.sigla}</th>
                                                <td>{item.nome_responsavel}</td>
                                                <td className="text-center">{item.cargo_responsavel}</td>
                                                <td>{item.nome_entidade_pai}</td>
                                                <td>{item.municipio}</td>
                                                <td>{item.tipo}</td>
                                                <td>{item.status == '0' ? 'Ativo' : 'Inativo'}</td>
                                                <td className="text-center" style={{width: 80}}>
                                                    <div className="dropdown dropleft">
                                                        <button onClick={toggleDrop} id="dropMenu" className="btn btn-secondary btn-sm" type="button"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="nav-icon fas fa-list"></i>
                                                        </button>

                                                        <div className="dropdown-menu" aria-labelledby="dropMenu">
                                                            <Link className="dropdown-item" to="#" onClick={e => editItem(e, item)} >Editar</Link>
                                                            <Link className="dropdown-item" to="#" onClick={e => viewItem(e, item)}>Visualizar</Link>
                                                            <Link className="dropdown-item" to="#" onClick={e => statusSwitch(item)} data-toggle="modal" data-target="#modalConfirm" >{item.status == '0' ? 'Desativar' : 'Ativar'}</Link>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td colSpan={9} className="text-center">Nenhum registro encontrado</td>
                                            </tr>
                                        )}
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div className="modal fade" id="modalConfirm" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            {entidade && (
                                <strong>Gostaria de {desstatus} a Entidade "{entidade.nome}" ?</strong>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger col-md-3" onClick={confirmStatus} >Sim</button>
                            <button type="button" className="btn btn-outline-dark col-md-3" data-dismiss="modal">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>

            <aside className="control-sidebar control-sidebar-dark">
            </aside>
            <Footer />
        </>
    );
}

export default connect((state) => ({
    sistema: state.sistema,
    usuario: state.usuario
}))(AnuncioConsulta);