import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { Container } from './styles';
import { connect, useDispatch, useSelector } from 'react-redux';

import logo from '../../assets/barcarena.png';
import logoCentro from '../../assets/logo_topo.png';

import config from '../../services/config';
import axios from 'axios';
import {toast} from 'react-toastify';
import api from '../../services/api';

function Login({ history, dispatch }) {

    const { id } = useParams();
    const [usuario, setUsuario] = useState({
        login: '',
        senha: ''
    });

    const entrar = async (e) => {
        e.preventDefault();
        if (usuario.login.length > 0 && usuario.senha.length > 0){
            const result = await api.post('/usuario/login', usuario);
            console.log(result.data);
            if (result.data){
                await localStorage.setItem('dom.usuario', JSON.stringify(result.data));
                toast.success('Autenticado com sucesso!');
                dispatch({ type: '@USUARIO/AUTENTICACAO_SUCESSO', user: result.data });
                history.push('/home');
               
            }
            else
                toast.warning('Dados incorretos!');
        }else{  
            toast.warning('Informe os dados de login e senha.');
        }
    }

    return (
        <Container className="container-fluid row no-gutter" style={{padding: 0}}>
            <div className="d-none d-md-flex col-md-5 col-lg-8 bg-image text-center" 
            style={{alignItems: 'center', justifyContent: 'center'}}>      
                <div className="">
                    <img src={logoCentro} className="img-fluid" style={{maxWidth: 400,}}/>          
                </div>
            </div>
            <div className="col-md-7 col-lg-4 bg-login d-flex align-items-center justify-content-center">
                    <div className="login d-flex align-items-center py-5">
                        <div className="col-md-10 col-lg-10 mx-auto">
                            <div className="d-flex align-items-center justify-content-center">
                                <img src={logo} style={{width: 200, }} />
                            </div>
                            <h3 className="login-heading mb-4 text-center">Bem vindo ao DOM!</h3>
                            <div className="mb-4 text-center font-subtext">Utilize usuário e senha para acessar o sistema.</div>
                            <form>
                                <div className="form-label-group">
                                    <input type="text" value={usuario.login} className="form-control" placeholder="Login" required autoFocus onChange={e => setUsuario({...usuario, login: e.target.value})} />
                                </div>

                                <div className="form-label-group">
                                    <input type="password" value={usuario.senha} className="form-control" placeholder="Senha" required  onChange={e => setUsuario({...usuario, senha: e.target.value})}/>
                                </div>

                                <button className="btn btn-lg btn-success btn-block btn-login text-uppercase font-weight-bold mb-2 bt-entrar" type="submit" onClick={entrar}>
                                    Entrar
                                </button>
                                <div className="text-center">                                    
                                </div>
                                <div className="text-center p-3">
                                    <small>Versão: {config.versao}</small>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
        </Container>
    );
}

export default connect((state) => ({
    sistema: state.sistema,
    usuario: state.usuario
}))(Login);