import React, {useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import pt from 'date-fns/locale/pt-BR';
import { connect } from 'react-redux';
import {format, startOfDay, startOfToday, isBefore, lastDayOfDecade} from 'date-fns';
import { Editor } from '@tinymce/tinymce-react'; 
import * as yup from 'yup';
import { toast } from 'react-toastify';

import SideBar from '../../components/SideBar';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';

import api from '../../services/api';
import { Link } from 'react-router-dom';

function TipoEntidadeCadastro({history, usuario, location}) {
    const schema = yup.object({
        descricao: yup.string().min(1).required(),
    });

    const [registro, setRegistro] = useState({
        id_tipo_entidade: '',
        descricao: '',
        tipo: '0',
        status: '0',
    });
    const [filtro, setFiltro] = useState(null);

    useEffect(() => {
        
        if (location.state)
        {
            if (location.state.registro)
                setRegistro(location.state.registro);
            
            if (location.state.filtro)
                setFiltro(location.state.filtro);

        }
        
    }, [])

    
    const gravar = async (e) => {
        e.preventDefault();

        const valido = await schema.isValid(registro);
        if (!valido)
        {
            toast.warning('Informe todos os campos obrigatórios.');
            return false;
        }
        if (registro.id_tipo_entidade)
        {
            var update = await api.put('/tipo_entidade', registro);
            console.log(update.data);
        }
        else
        {
            var save = await api.post('/tipo_entidade', registro);
            console.log(save.data);
        }
        toast.success('Registro salvo com sucesso!');
        history.replace('/tiposentidade', {filtro});
    }

    const voltar = (e) => {
        e.preventDefault();
        history.replace('/tiposentidade', {filtro});
    }

    return (
        <>
            <NavBar history={history} />
            <SideBar opcao="tiposentidade"/>
            <div className="content-wrapper">            
                <section className="content main-content ">
                    <div className="container-fluid">
                        <br />
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/tiposentidade">Tipo de Entidade</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Cadastro</li>
                            </ol>
                        </nav>

                        <div className="card">
                            <div className="card-body">
                                <form>
                                    <div className="form-row">                                        
                                        <div className="form-group col-md-6">
                                            <label>Descrição *</label>
                                            <textarea type="text" rows={3} className="form-control" value={registro.descricao} onChange={e => setRegistro({...registro, descricao: e.target.value})} />
                                        </div> 
                                    </div> 

                                    <div className="form-row">                                         
                                        <div className="form-group col-md-3">
                                            <label>Tipo</label>
                                            <select className="form-control" onChange={e => setRegistro({...registro, tipo: e.target.value})} value={registro.tipo}>
                                                <option value="0">Executivo</option>
                                                <option value="1">Administração Direta</option>
                                                <option value="2">Administração Indireta</option>
                                                <option value="3">Legislativo</option>
                                            </select>
                                        </div>  
                                    </div> 

                                    <div className="form-row">                                         
                                        <div className="form-group col-md-2">
                                            <label>Status</label>
                                            <select className="form-control" onChange={e => setRegistro({...registro, status: e.target.value})} value={registro.status}>
                                                <option value="0">Ativo</option>
                                                <option value="1">Desativado</option>
                                            </select>
                                        </div>  
                                    </div>  
                                    

                                </form>
                            </div>
                        </div>

                        <hr />
                        <div className="row">
                            <div className="col d-flex flex-row" >
                                <button className="btn btn-primary mr-4 col-md-2" onClick={gravar}>Salvar</button>
                                <Link className="btn btn-primary col-md-2" to="#" onClick={voltar}>Voltar</Link>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <aside className="control-sidebar control-sidebar-dark">
            </aside>
            <Footer />
        </>
    );
}

export default connect((state) => ({
    sistema: state.sistema,
    usuario: state.usuario
}))(TipoEntidadeCadastro);