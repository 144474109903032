import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {format, startOfDay, startOfToday, isBefore, lastDayOfDecade} from 'date-fns';
import * as yup from 'yup';

import SideBar from '../../components/SideBar';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';

import api from '../../services/api';
import { Link } from 'react-router-dom';

function PublicacaoView({history, usuario, location}) {

    const date = startOfDay(new Date());
    const [dados, setDados] = useState({
        id_tipo_ato: '',
        id_tipo_publicacao: '',
        titulo: '',
        descricao: '',
        dt_cadastro: '',
        dt_publicacao: '',
    });
    const [filtro, setFiltro] = useState(null)
    
    const [atos, setAtos] = useState(null);
    const [tipos, setTipos] = useState(null);


    useEffect(() => {
        async function carregaTabela(){
            const result = await api.get('ato');
            setAtos(result.data);

            const resultTipo = await api.get('tipo');
            setTipos(resultTipo.data);
            

            if (location.state.filtro)
                setFiltro(location.state.filtro);

            if (location.state.publicacao)
                setDados(location.state.publicacao);
        }
        carregaTabela();
    }, [])

    const voltar = async (e) => {
        e.preventDefault();
        console.log(filtro);        
        history.push('/publicacoes', {filtro});
        
    }


    return (
        <>
            <NavBar history={history} />
            <SideBar opcao="publicacoes"/>
            <div className="content-wrapper">            
                <section className="content main-content">
                    <div className="container-fluid">
                        <br />
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/publicacoes">Publicações</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Detalhes</li>
                            </ol>
                        </nav>

                        <form>
                            <div className="form-row">               
                                <div className="form-group col-md-4">
                                    <label className="">Formato de Publicação</label>
                                    <select className="form-control" value={dados.id_tipo_publicacao} disabled>
                                        <option value="">...</option>
                                        {tipos && tipos.map((item, index) => 
                                            <option key={index} value={item.id_tipo_publicacao}>{item.descricao}</option>
                                        )}
                                    </select>
                                </div>  
                            </div>  
                            <div className="form-row"> 
                                <div className="form-group col-md-2">
                                    <label>Data Cadastro</label>
                                    <input type="text" className="form-control" value={dados.dt_cadastro} disabled/>
                                </div>                 
                                <div className="form-group col-md-2">
                                    <label className="">Data da Publicação</label>
                                    <input type="text" className="form-control" value={dados.dt_publicacao} disabled/>
                                </div>                                
                                <div className="form-group col-md-4">
                                    <label className="">ATO</label>
                                    <select className="form-control" value={dados.id_tipo_ato} disabled>
                                        <option value="">...</option>
                                        {atos && atos.map((item, index) => 
                                            <option key={index} value={item.id_tipo_ato}>{item.nome}</option>
                                        )}
                                    </select>
                                </div>                                
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-10">
                                    <label className="">Titulo</label>
                                    <input type="text" className="form-control" value={dados.titulo} disabled/>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-10">
                                    <label className="">Detalhes</label>
                                    <div className="" dangerouslySetInnerHTML={ { __html: dados.descricao}}>
                                    </div>                                 
                                </div>
                            </div>
                        </form>
                        
                        <hr />
                        <div className="row">
                            <div className="col d-flex flex-row" >
                                <Link className="btn btn-primary col-md-2" to="#" onClick={voltar} >Voltar</Link>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <aside className="control-sidebar control-sidebar-dark">
            </aside>
            <Footer />
        </>
    );
}

export default connect((state) => ({
    sistema: state.sistema,
    usuario: state.usuario
}))(PublicacaoView);