import React, { useEffect, useState } from 'react';

import SideBar from '../../components/SideBar';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import { connect, useDispatch, useSelector } from 'react-redux';
import api from '../../services/api';
import { Link } from 'react-router-dom';
import config from '../../services/config';
import logo from '../../assets/logo2.png';

function Home({history, usuario}) {
    const [publicacoes, setPublicacoes] = useState(null);
    const [diarios, setDiarios] = useState(null);
    const [usuarios, setUsuarios] = useState(null);
    const [entidades, setEntidades] = useState(null);
    const [perfil, setPerfil] = useState(null);

    useEffect(() => {
        console.log(usuario);
        async function load(){

            const perfil_ = await api.get('/perfil');

            const perfil_atual = perfil_.data.find(per => per.id_perfil == usuario.id_perfil);

            setPerfil(perfil_atual);

            if ('1,2,4'.indexOf(usuario.id_perfil) > -1)
            {
                const publicacoes_ = await api.get('/publicacao/contador');
                setPublicacoes(publicacoes_.data);

                const diarios_ = await api.get('/diario/contador');
                setDiarios(diarios_.data);

                const entidades_ = await api.get('/entidade/contador');
                setEntidades(entidades_.data);

                const usuarios_ = await api.get('/usuario/contador');
                setUsuarios(usuarios_.data);
            }
        }

        load();
    }, []);

  return (
    <>
        <NavBar history={history}/>
        <SideBar opcao="home"/>
        <div className="content-wrapper main-content">            
            <section className="content">
                <div className="container-fluid">
                    <p className="msg_inicial">
                        Olá {usuario.nome} - {perfil && perfil.nome} <br />
                        <strong>Bem-vindo(a) ao Diário Oficial Municipal de Barcarena - DOM!</strong>
                    </p>

                    <br />
                    {'1,2,4'.indexOf(usuario.id_perfil) > -1 && (
                        <div className="row">
                            <div className="col-lg-3 col-6 ">
                                <div className="small-box bg-light box-contador">
                                    <div className="inner ">
                                    <p className=" fonttitulocontador " >PUBLICAÇÕES CADASTRADAS</p>
                                        <h1 className=" fontcontador" >{publicacoes ? publicacoes.total : 'Carregando'}</h1>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-file"></i>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-light box-contador">
                                    <div className="inner">
                                        <p className=" fonttitulocontador " >DIÁRIOS PUBLICADOS</p>
                                        <h1 className=" fontcontador" >{diarios ? diarios.total : 'Carregando'}</h1>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-newspaper"></i>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-light box-contador">
                                    <div className="inner">
                                        <p className=" fonttitulocontador " >ENTIDADES CADASTRADAS</p>
                                        <h1 className=" fontcontador" >{entidades ? entidades.total : 'Carregando'}</h1>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-city"></i>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-light box-contador">
                                    <div className="inner">
                                        <p className=" fonttitulocontador " >USUÁRIOS CADASTRADOS</p>
                                        <h1 className=" fontcontador" >{usuarios ? usuarios.total : 'Carregando'}</h1>
                                        
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-users"></i>
                                    </div>
                                </div>
                            </div>

                        </div>
                    )}
                </div>
            </section>
        </div>
        <aside className="control-sidebar control-sidebar-dark">
        {/* Control sidebar content goes here */}
        </aside>
        <Footer />
    </>
  );
}

export default connect((state) => ({
    sistema: state.sistema,
    usuario: state.usuario
}))(Home);