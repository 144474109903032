function isMobile() {
  var userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.search(/(android|avantgo|blackberry|bolt|boost|cricket|docomo|fone|hiptop|mini|mobi|palm|phone|pie|tablet|up\.browser|up\.link|webos|wos)/i) !== -1)
    return true;
}

export default {
  versao: '1.0.12',
  nome: 'DOM',
  isMobile: isMobile(),
  urlLogo: 'http://diariooficial.tecnologia.ws/logos/',
  baseURL: "http://domapi.tecnologia.ws/",
  // baseURL: "http://192.168.0.11:3333/",
  // baseURL: "https://aplicativomaisescola.com.br/dom-api/",
};
