import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {format, startOfDay, startOfToday, isBefore, lastDayOfDecade} from 'date-fns';
import { Editor } from '@tinymce/tinymce-react'; 
import { toast } from 'react-toastify';

import SideBar from '../../components/SideBar';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import {Link} from 'react-router-dom';
import api from '../../services/api';
import config from '../../services/config';

function AnuncioCadastro({history, usuario, location}) {

    const date = startOfDay(new Date());
    const [anuncio, setAnuncio] = useState('');
    const [anuncioInicial, setAnuncioInicial] = useState('');
    const [id_anuncio, setIdAnuncio] = useState('');

    useEffect(() => {
        async function carregaTabela(){
            const result = await api.get('anuncio');
            console.log(result.data);
            setAnuncio(result.data.texto);
            setAnuncioInicial(result.data.texto);
        }

        carregaTabela();
    }, [])


    const gravar = async (e) => {
        e.preventDefault();
        const dados = {
            id_anuncio,
            anuncio,
        }

        var resultPudate = await api.put('/anuncio', dados);
        console.log(resultPudate.data);

        toast.success('Registro salvo com sucesso!');
    }

    const handleChangeDescricao = (content, editor) => {
        setAnuncio(content.level.content);
    }

    return (
        <>
            <NavBar history={history} />
            <SideBar opcao="anuncio"/>
            <div className="content-wrapper">            
                <section className="content main-content ">
                    <div className="container-fluid">
                        <br />
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">Cadastro da Anúncio</li>
                            </ol>
                        </nav>

                        <div className="card">
                            <div className="card-body">
                                <form>
                                    <div className="form-row">
                                        <div className="form-group col-md-10">
                                            <Editor
                                                apiKey="21c4cvqt6brn2olq69wuah32od4hbl6td6lbrtzbsnk0473c"
                                                initialValue={anuncioInicial}
                                                init={{
                                                    height: 540,
                                                    menubar: true,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar: 'undo redo | formatselect | ' +
                                                    'bold italic backcolor | alignleft aligncenter ' +
                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                    'removeformat | image | table | help',
                                                    content_style: 'body { font-family:Verdana,Arial,sans-serif; font-size: 14px }',
                                                    // plugins: [
                                                    //     'advlist autolink lists link image', 
                                                    //     'charmap print preview anchor help',
                                                    //     'searchreplace visualblocks code',
                                                    //     'insertdatetime media table paste wordcount'
                                                    // ],
                                                    // toolbar:
                                                    //     'undo redo | formatselect | bold italic | \
                                                    //     alignleft aligncenter alignright | \
                                                    //     bullist numlist outdent indent | image | help',
                                                    selector: 'textarea',
                                                    language: 'pt_BR',
                                                    file_picker_types: 'image',
                                                    image_uploadtab: true,
                                                    images_upload_url: config.baseURL + 'upload',
                                                    images_upload_base_path: '',
                                                }}
                                                onChange={handleChangeDescricao}
                                            />                                   
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <hr />
                        <div className="row">
                            <div className="col d-flex flex-row" >
                                <button className="btn btn-primary mr-4 col-md-2" onClick={gravar}>Salvar</button>
                                <Link className="btn btn-primary col-md-2" to="/home">Voltar</Link>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <aside className="control-sidebar control-sidebar-dark">
            </aside>
            <Footer />
        </>
    );
}

export default connect((state) => ({
    sistema: state.sistema,
    usuario: state.usuario
}))(AnuncioCadastro);