import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import { connect, useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import pt from 'date-fns/locale/pt-BR';
import {format, startOfDay, isBefore, startOfToday, addDays, subDays} from 'date-fns';
import * as $ from 'jquery';

import SideBar from '../../components/SideBar';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import api from '../../services/api';
import config from '../../services/config';
import { Link } from 'react-router-dom';

function DiarioCadastro({history, usuario}) {

    const date = startOfDay(new Date());
    const [novoEdital, setNovoEdital] = useState([]);
    const [publicacao, setPublicacao] = useState(null);    
    const [edicao, setEdicao] = useState([]);    
    const [atos, setAtos] = useState(null);
    const [id_tipo_ato, setTipoAto] = useState('');
    const [entidades, setEntidades] = useState(null);
    const [entidade, setEntidade] = useState('');
    const [data_cadastro, setDataCadastro] = useState('');
    const [data_publicacao, setDataPublicacao] = useState(format(startOfToday(date), 'yyyy-MM-dd'));
    const [palavra_chave, setPalavraChave] = useState('');
    const [status, setStatus] = useState('');
    const [lista, setLista] = useState(null);
    const [horaParaPublicacao, setHoraParaPublicacao] = useState('');

    const [data_diario, setDataDiario] = useState('');
    const [data_diario_pick,setDataDiarioPick] = useState(null);

    // eslint-disable-next-line
    const [dataCadastroPick, setDataCadastroPick] = useState(null);
    // eslint-disable-next-line
    const [horaParaPublicacaoPick, setHoraParaPublicacaoPick] = useState(null);
    // eslint-disable-next-line
    const [dataPublicacaoPick, setDataPublicacaoPick] = useState(date);
    // eslint-disable-next-line
    const [dataParaPublicacaoPick, setDataParaPublicacaoPick] = useState(addDays(date, 1));

  
    function inputCadastroChange(e){
        setDataCadastroPick(e);
        setDataCadastro(e);
    }
    function limpaDataCadastro(e){
        setDataCadastroPick('');
        setDataCadastro('');
    }

    function inputPublicacaoChange(e){
        setDataPublicacaoPick(e);
        setDataPublicacao(e);
    }
    function limpaDataPublicacao(e){
        setDataPublicacaoPick('');
        setDataPublicacao('');
    }

    function inputDataPulicacaoChange(e){
        setDataParaPublicacaoPick(e);
    }

    function inputHoraPulicacaoChange(e){
        setHoraParaPublicacaoPick(e);
        setHoraParaPublicacao(format(e, 'HH:mm'));
    }

    useEffect(() => {
        async function carregaTabela(){
            const resultAtos = await api.get('/ato');
            setAtos(resultAtos.data);

            const resultEntidades = await api.get('/entidade', { params: {
                nome: '',        
                sigla: '',
                nome_entidade_pai: '',
                id_municipio: '',
                id_tipo_entidade: '',
                status: '',
            }});
            setEntidades(resultEntidades.data);

            const resultPublic = await api.get('/publicacoes/novo_diario', {params :{
                data_cadastro,
                data_publicacao,
                palavra_chave,
                id_tipo_ato,
                status,
                id_entidade: entidade,
                situacao: ''
            }});

            setLista(resultPublic.data ? resultPublic.data : null);
        }

        carregaTabela();
    }, [])

    const consutlar = async (e) => {
        e.preventDefault();
        const result = await api.get('/publicacoes/novo_diario', {params :{
            data_cadastro,
            data_publicacao,
            palavra_chave,
            status,
            id_tipo_ato,
            id_entidade: entidade,
            situacao: ''
        }});

        const arrayResult = result.data.filter(item => {            
            const _item = novoEdital.find(pub => pub.id_publicacao === item.id_publicacao);
            return _item ? false : true;
        });

        setLista(arrayResult);
    }
        
    function viewItem(item){
        console.log(item);
        setPublicacao(item);
        window.$('#modalConfirm').modal('toggle');
    }

    async function adicionar(i){
        let lista_ = lista;
        let selectedItem = lista_.splice(i, 1);
        setLista(lista_);
        selectedItem.forEach(element => {
            setNovoEdital([...novoEdital, element]);
        });
    }

    async function remover(i){
        let novoEdital_ = novoEdital;
        let selectedItem = novoEdital.splice(i, 1);
        setNovoEdital(novoEdital);
        selectedItem.forEach(element => {
            setLista([...lista, element]);
        });
    }

    async function gravar(e){
        e.preventDefault();
        // console.log(dataParaPublicacaoPick, horaParaPublicacao);
        const result = await api.post('/diario', {
            edicao: 1,
            nome_usuario: usuario.nome,
            id_usuario: usuario.id_usuario,
            data_publicacao: format(dataParaPublicacaoPick, 'yyyy-MM-dd') + 'T' + horaParaPublicacao + ':00',
            publicacoes: novoEdital,
        });

        history.replace('/diario');
    }

    function updateDataDiario(e) {
        setDataDiarioPick(e);
        setDataCadastro(e);
    }

    function limpaDataDiario(){
        setDataDiarioPick('');
        setDataCadastro('');
    }

    return (
        <>
            <NavBar history={history} />
            <SideBar opcao="diario"/>
            <div className="content-wrapper ">
                <section className="content main-content">
                    <div className="container-fluid">
                        <br />
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">Diário Cadastro</li>
                            </ol>
                        </nav>
                        <div className="card">
                            <div className="card-body">
                               
                                <form className={config.isMobile ? '' : ''}>
                                    <div className="form-row">
                                        <div className={'form-group col-md-2'}>
                                            <label>Data Cadastro</label>
                                            <DatePicker
                                                name="data"
                                                dateFormat="dd/MM/yyyy"
                                                selected={dataCadastroPick}
                                                onChange={inputCadastroChange}
                                                onClickOutside={limpaDataCadastro}
                                                className="form-control"
                                                locale={pt} />
                                        </div>
                                        <div className={'form-group col-md-2'}>
                                            <label>Data Publicação</label>
                                            <DatePicker
                                                placeholderText="Data da Publicação"
                                                name="data"
                                                dateFormat="dd/MM/yyyy"
                                                selected={dataPublicacaoPick}
                                                onChange={inputPublicacaoChange}
                                                onClickOutside={limpaDataPublicacao}
                                                className="form-control"
                                                locale={pt} />
                                        </div>
                                        <div className={'form-group col-md-3'}>
                                            <label>Entidade</label>
                                            <select className="form-control" onChange={e => setEntidade(e.target.value)} value={entidade}>
                                                <option value="">Todos</option>
                                                {entidades && entidades.map((item, index) => 
                                                    <option key={index} value={item.id_entidade}>{item.nome}</option>
                                                )}
                                            </select>
                                        </div>
                                        <div className={'form-group col-md-3'}>
                                            <label>Tipo do ATO</label>
                                            <select className="form-control" onChange={e => setTipoAto(e.target.value)} value={id_tipo_ato}>
                                                <option value="">Todos</option>
                                                {atos && atos.map((item, index) => 
                                                    <option key={index} value={item.id_tipo_ato}>{item.nome}</option>
                                                )}
                                            </select>
                                        </div>
                                        <div className={'form-group col-md-2 d-flex align-items-md-end'}>
                                            <a className="btn btn-primary btn-block" onClick={consutlar}>Consultar</a>                                            
                                        </div>
                                    </div>
                                </form>
                            
                                <hr />

                                <div className="tabela">
                                    <table className="table table-striped">
                                        
                                        <thead >
                                            <tr>
                                            <th style={{width: 80}} className="text-center" >ID</th>
                                            <th style={{width: 90}} >DATA CADASTRO</th>
                                            <th scope="col">ENTIDADE</th>
                                            <th scope="col">TIPO DO ATO</th>
                                            <th scope="col">TÍTULO</th>
                                            <th style={{width: 90}} >DATA PUBLICAÇÃO</th>
                                            <th style={{width: 100}} scope="col">STATUS</th>
                                            <th className="text-center" style={{width: 80}}>OPÇÕES</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {lista ? lista.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="text-center">{item.codigo_identificador}</td>
                                                    <td>{item.dt_cadastro}</td>
                                                    <td>{item.nome_entidade}</td>
                                                    <td>{item.ato}</td>
                                                    <td>{item.titulo}</td>
                                                    <td className="text-center">{item.dt_publicacao}</td>
                                                    <td>{item.situacao_des}</td>
                                                    <td className="text-center d-flex flex-row justify-content-center" >
                                                        <i onClick={() => viewItem(item)} style={{fontSize: 20, cursor: 'pointer'}} className="fas fa-eye mr-2"></i>
                                                        {item.situacao == 1 ? (
                                                            <i onClick={() => adicionar(index)} style={{fontSize: 20, cursor: 'pointer'}} className="fas fa-plus-circle"></i>
                                                        ):(
                                                            <i style={{fontSize: 20, cursor: 'not-allowed', color: 'Silver'}} className="fas fa-plus-circle"></i>
                                                        )}
                                                    </td>
                                                </tr>
                                            )) : (
                                                <tr>
                                                    <td></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>

                                <hr/>

                                <h4>NOVO DIÁRIO</h4>
                                <div className="tabela">
                                    <table className="table table-striped">
                                        <thead >
                                            <tr>
                                            <th style={{width: 80}} >ID</th>
                                            <th style={{width: 90}} >DATA CADASTRO</th>
                                            <th scope="col">ENTIDADE</th>
                                            <th scope="col">TIPO DO ATO</th>
                                            <th scope="col">TÍTULO</th>
                                            <th style={{width: 90}} >DATA PUBLICAÇÃO</th>
                                            <th style={{width: 100}} scope="col">STATUS</th>
                                            <th className="text-center"  style={{width: 80}}>OPÇÕES</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {novoEdital ? novoEdital.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="text-center">{item.codigo_identificador}</td>
                                                    <td className="text-center">{item.dt_cadastro}</td>
                                                    <td>{item.nome_entidade}</td>
                                                    <td>{item.ato}</td>
                                                    <td>{item.titulo}</td>
                                                    <td className="text-center">{item.dt_publicacao}</td>
                                                    <td>{item.situacao_des}</td>
                                                    <td className="text-center d-flex flex-row justify-content-center">                                                                                                        
                                                        <i onClick={() => viewItem(item)} style={{fontSize: 20, cursor: 'pointer'}} className="fas fa-eye mr-2"></i>
                                                        <i onClick={() => remover(index)} style={{fontSize: 20, cursor: 'pointer'}} className="fas fa-trash-alt"></i>                                                    
                                                    </td>
                                                </tr>
                                            )) : (
                                                <tr>
                                                    <td colSpan={5} className="text-center">Nenhuma publicação selecionada</td>
                                                </tr>
                                            )}
                                            
                                        </tbody>
                                    </table>
                                </div>

                                <form >
                                    <div className="form-row">
                                        <div className={'form-group col-md-2'}>
                                            <label>Data do Diário:</label>
                                            <DatePicker
                                                name="data"
                                                dateFormat="dd/MM/yyyy"
                                                selected={data_diario_pick}
                                                onChange={updateDataDiario}
                                                onClickOutside={limpaDataDiario}
                                                className="form-control"
                                                locale={pt} />
                                        </div>
                                    </div>
                                </form>
                                
                                <hr/>

                                <button type="button" className="btn btn-primary mr-2 col-md-2" onClick={gravar} disabled={novoEdital.length == 0}>Salvar</button>                
                                <Link to="/diario" className="btn btn-primary col-md-2">Voltar</Link>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div className="modal fade" id="modalConfirm" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        {publicacao && (
                            <>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">
                                        PUBLICAÇÃO Nº {publicacao.id}
                                    </h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <strong>{publicacao.titulo}</strong><br />
                                    <div className="" dangerouslySetInnerHTML={ { __html: publicacao.descricao}}>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-dark col-md-3" data-dismiss="modal">Fechar</button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <aside className="control-sidebar control-sidebar-dark">
            </aside>
            <Footer />
        </>
    );
}

export default connect((state) => ({
    sistema: state.sistema,
    usuario: state.usuario
}))(DiarioCadastro);