import React, {useEffect, useState, useRef} from 'react';
import { connect } from 'react-redux';
import CryptoJS from 'crypto-js';
import { toast } from 'react-toastify';

import SideBar from '../../components/SideBar';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';

import api from '../../services/api';
import { Link } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';

function Certificado({history, usuario, location}) {
    
    const chave = 'bukowski';

    const [newfile, setNewFile] = useState(null);
    const [senha, setSenha] = useState('');    
    const [senhaCrypto, setSenhaCrypto] = useState('');    
    const [certificado, setCertificado] = useState();

    function handleFile (e) {
        if (e.target.files.length > 0)
        {
            setNewFile(e.target.files[0]);
        }else{
            setNewFile(null);
        }
    }

    useEffect(() => {
        
        async function load(){
            const res = await api.get('/certificado');            
            setCertificado(res.data);
            console.log(res.data);
        }

        load()
        
    }, [])

    
    const gravar = async (e) => {
        e.preventDefault();

        if (newfile && senha.length > 0)
        {
            let postdata = new FormData();
            postdata.append('file', newfile);
            const upload = await api.post('/certificado/upload', postdata);            
            console.log(upload.data)

            const certificado = {
                id: uuidv4(),
                path: upload.data.name,
                senha: senha
            };

            var save = await api.post('/certificado', certificado);
            console.log(save.data);

            toast.success('Registro salvo com sucesso!');
            history.push('/home');
        }else{
            toast.warning('Campos obrigatórios!');
        }


    }

    function handleSenha (e){
        const senha_ = CryptoJS.AES.encrypt(e.target.value, chave).toString();
        console.log(senha_);
        setSenha(e.target.value);
        setSenhaCrypto(senha_);
    }

    return (
        <>
            <NavBar history={history} />
            <SideBar opcao="tiposentidade"/>
            <div className="content-wrapper">            
                <section className="content main-content ">
                    <div className="container-fluid">
                        <br />
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/tiposentidade">Certificado</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Cadastro</li>
                            </ol>
                        </nav>

                        <div className="card">
                            <div className="card-body">
                                {certificado && (
                                    <>
                                    <div className="card bg-primary">
                                        <div className="card-body">
                                            <strong>Certificado cadastrado: </strong> {certificado.path}
                                        </div>
                                    </div>
                                    <hr />
                                    <br />
                                    </>
                                )}
                                <form>
                                    <div className="form-row">                                                                            
                                        <div className="form-group col-md-3 mb-0">                                        
                                            <label>Novo Certificado *</label>
                                        </div>
                                    </div>
                                    <div className="form-row">                                                                            
                                        <div className="form-group col-md-6 d-flex align-items-end">                                           
                                            <div className="custom-file">
                                                <input onChange={handleFile} type="file" className="custom-file-input"/>
                                                <label  className="custom-file-label">{newfile ? newfile.name : 'Seleione um certificado'}</label>
                                            </div>
                                        </div> 
                                    </div> 

                                    <div className="form-row">                                         
                                        <div className="form-group col-md-3">
                                            <label>Nova Senha *</label>
                                            <input type="password" className="form-control" onChange={handleSenha} value={senha} />
                                        </div>  
                                    </div> 

                                </form>
                            </div>
                        </div>

                        <hr />
                        <div className="row">
                            <div className="col d-flex flex-row" >
                                <button className="btn btn-primary mr-4 col-md-2" onClick={gravar}>Salvar</button>
                                <Link className="btn btn-primary col-md-2" to="/home">Voltar</Link>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <aside className="control-sidebar control-sidebar-dark">
            </aside>
            <Footer />
        </>
    );
}

export default connect((state) => ({
    sistema: state.sistema,
    usuario: state.usuario
}))(Certificado);