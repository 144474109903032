import React, {useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import pt from 'date-fns/locale/pt-BR';
import { connect } from 'react-redux';
import {format, startOfDay, startOfToday, isBefore, lastDayOfDecade} from 'date-fns';
import { Editor } from '@tinymce/tinymce-react'; 
import * as yup from 'yup';
import { toast } from 'react-toastify';

import SideBar from '../../components/SideBar';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';

import api from '../../services/api';
import { Link } from 'react-router-dom';

function AlteraSenha({history, usuario, location}) {
    const schema = yup.object({
        senha: yup.string().min(1).required(),
        novasenha: yup.string().min(1).required(),
    });
    const date = startOfDay(new Date());
    const [registro, setRegistro] = useState({
        login: usuario.login,
        id_usuario: usuario.id_usuario,
        senha: '',
        novasenha: '',
    });
    const [senhacorreta, setSenhaCorreta] = useState(false);

    const gravar = async (e) => {
        e.preventDefault();

        const valido = await schema.isValid(registro);
        if (!valido)
        {
            toast.warning('Informe todos os campos obrigatórios.');
            return false;
        }
        
        var update = await api.put('/usuario/senha', registro);
        console.log(update.data);

        toast.success('Senha alterada com sucesso!');
        history.replace('/home');
    }

    const validaUsuario = async (e) => {
        var user = await api.get('/usuario/validarsenha', { params: registro});
        if (user.data.length > 0)
        {
            setSenhaCorreta(true);
            document.getElementById("novasenha").focus();
        }else{
            e.target.value = '';
            setRegistro({...registro, senha: ''});
            setSenhaCorreta(false);
            toast.warning('Senha incorreta!');            
        }
    }

    const voltar = (e) => {
        e.preventDefault();
        history.goBack();
    }

    return (
        <>
            <NavBar history={history} />
            <SideBar opcao="alterasenha"/>
            <div className="content-wrapper">            
                <section className="content main-content ">
                    <div className="container-fluid">
                        <br />
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">Alterar Senha</li>
                            </ol>
                        </nav>

                        <div className="card">
                            <div className="card-body">
                                <form>
                                    <div className="form-row">
                                        <div className="form-group col-md-8">
                                            <label>Nome *</label>
                                            <label className="form-control disabled" >
                                                {usuario.nome}
                                            </label>
                                        </div>  
                                        
                                    </div>  
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label>E-mail *</label>
                                            <label className="form-control disabled">
                                                {usuario.email}
                                            </label>
                                        </div>  
                                    </div>
                                      
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label>Senha Antiga *</label>
                                            <input type="password"  className="form-control" onBlur={validaUsuario} autoComplete="off" value={registro.senha} onChange={e => setRegistro({...registro, senha: e.target.value})}/>
                                            {senhacorreta && (
                                                <div className="" style={{color: 'green'}}>
                                                    <i className="fas fa-book nav-icon mt-2 mr-2"></i>
                                                    Senha confirmada.
                                                </div>
                                            )}
                                        </div>  
                                        <div className="form-group col-md-6">
                                            
                                        </div>
                                    </div> 

                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label>Nova Senha *</label>
                                            <input type="password" id="novasenha" autoComplete="off" disabled={!senhacorreta} className="form-control" value={registro.novasenha} onChange={e => setRegistro({...registro, novasenha: e.target.value})}/>
                                        </div>  
                                    </div> 

                                </form>
                            </div>
                        </div>

                        <hr />
                        <div className="row">
                            <div className="col d-flex flex-row" >
                                <button className="btn btn-primary mr-4 col-md-2" onClick={gravar}>Salvar</button>
                                <Link className="btn btn-primary col-md-2" to="#" onClick={voltar}>Voltar</Link>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <aside className="control-sidebar control-sidebar-dark">
            </aside>
            <Footer />
        </>
    );
}

export default connect((state) => ({
    sistema: state.sistema,
    usuario: state.usuario
}))(AlteraSenha);