import React from 'react';

import config from '../../services/config';

function Footer() {
  return (
    <footer className="main-footer" style={{
      display: 'flex',
      flexDirection: 'column',
      position: 'fixed',
      bottom: 0,
      background: '#343a40',
      width: '100%',
    }}>
        <strong style={{
          alignSelf: 'flex-start'
        }}>&copy; 2021 Diário Oficial Eletrônico Municipal - DOM.</strong>
        <small style={{
          alignSelf: 'flex-start'
        }}>Órbita Tecnologia. Todos os direitos reservados.</small>
        
    </footer>
  );
}

export default Footer;