import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import { connect } from 'react-redux';
import * as $ from 'jquery';

import SideBar from '../../components/SideBar';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import api from '../../services/api';
import config from '../../services/config';
import { Link } from 'react-router-dom';

function TipoEntidadeConsulta({history, location}) {

    const [registro, setRegistro] = useState(null); 
    const [lista, setLista] = useState(null);
    const [desstatus, setDesStatus] = useState('');
    const [filtro, setFiltro] = useState({
        descricao: '',
        tipo: '',
        status: '',
    })

    function toggleDrop(e) {
        e.preventDefault();
        return false;
    }

    useEffect(() => {
        async function carregaTabela(){
            let filtro_ = {...filtro};
            if (location.state)
                if (location.state.filtro)
                {
                    filtro_ = location.state.filtro;
                    setFiltro(location.state.filtro);
                }
            
            const result = await api.get('/tipos_entidade', {params : filtro_});
            console.log(result);
            setLista(result.data ? result.data : null);
        }

        carregaTabela();
    }, []);

    const consutlar = async (e) => {
        e.preventDefault();
        const result = await api.get('/tipos_entidade', {params : filtro});
        console.log(result);
        setLista(result.data ? result.data : null);
    }
        
    // function viewItem(e, item){
    //     e.preventDefault();
    //     history.push('/tiposentidade_view', {registro: item});
    // }

    function editItem(e, item){
        e.preventDefault();
        history.push('/tiposentidade_cad', {registro: item});
    }

    async function confirmStatus(){
        const novostatus = registro.status === 1 ? 0 : 1;
        const desstatus = novostatus === 1 ? 'Desativado' : 'Ativado';

        const del = await api.put('/tipo_entidade/status', {id_ato: registro.id_ato, status: novostatus});
        const result = await api.get('/tipos_entidade', {params : filtro});
        
        window.$('#modalConfirm').modal('toggle');
        toast.success(`Ato ${desstatus} com sucesso`);
        setLista(result.data);
    }

    function statusSwitch(item){

        const novostatus = item.status === 1 ? 0 : 1;
        const desstatus = novostatus === 1 ? 'DESATIVAR' : 'ATIVAR';
        setDesStatus(desstatus);
        setRegistro(item);
    }
    
    const voltar = (e) => {
        e.preventDefault();
        history.replace('/entidades', {filtro});
    }

    const novo = (e) => {
        e.preventDefault();
        history.replace('/tiposentidade_cad', {filtro});
    }
    
    
    return (
        <>
            <NavBar history={history} />
            <SideBar opcao="tiposentidade"/>
            <div className="content-wrapper">            
                <section className="content main-content">
                    <div className="container-fluid">
                        <br />
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">Consulta Tipos de Entidade</li>
                            </ol>
                        </nav>
                        <div className="card">
                            <div className="card-body">
                                <form>
                                    <div className="form-row">

                                        <div className="form-group col-md-4">
                                            <label>Descrição</label>
                                            <input type="text" className="form-control" value={filtro.descricao} onChange={e => setFiltro({...filtro, descricao: e.target.value})}/>
                                        </div>  

                                        <div className="form-group col-md-3">
                                            <label>Tipo</label>
                                            <select className="form-control" onChange={e => setFiltro({...filtro, tipo: e.target.value})} value={filtro.tipo}>
                                                <option value="">Todos</option>
                                                <option value="0">Executivo</option>
                                                <option value="1">Administração Direta</option>
                                                <option value="2">Administração Indireta</option>
                                                <option value="3">Legislativo</option>
                                            </select>
                                        </div>  

                                        <div className="form-group col-md-2">
                                            <label>Status</label>
                                            <select className="form-control" onChange={e => setFiltro({...filtro, status: e.target.value})} value={filtro.status}>
                                                <option value="">Todos</option>
                                                <option value="0">Ativo</option>
                                                <option value="1">Desativado</option>
                                            </select>
                                        </div>                                                                             
                                    </div>
                                    <div className="form-row">
                                        <div className={'form-group col'}>
                                            <button className="btn btn-primary col-md-3 col-sm-6" onClick={consutlar}>Consultar</button>
                                        </div>
                                        <div className={'form-group col text-right'}>
                                            <Link className="btn btn-success col-md-3 col-sm-6" to="#" onClick={novo}>Novo</Link>
                                        </div>
                                    </div>
                                </form>
                            
                                <hr />
                                <table className="table table-striped">
                                    <thead >
                                        <tr>
                                            <th>DESCRIÇÃO</th>
                                            <th>TIPO</th>
                                            <th>STATUS</th>
                                            <th style={{width: 50}}>OPÇÕES</th>
                                        </tr>
                                    </thead> 
                                    <tbody>
                                        {lista ? lista.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.descricao}</td>
                                                <td>{item.tipo_des}</td>
                                                <td className="text-center" style={{width: 80}}>{item.status == '0' ? 'Ativo' : 'Inativo'}</td>
                                                <td className="text-center" >
                                                    <div className="dropdown dropleft">
                                                        <button onClick={toggleDrop} id="dropMenu" className="btn btn-secondary btn-sm" type="button"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="nav-icon fas fa-list"></i>
                                                        </button>

                                                        <div className="dropdown-menu" aria-labelledby="dropMenu">
                                                            <Link className="dropdown-item" to="#" onClick={e => editItem(e, item)}>Editar</Link>
                                                            {/* <Link className="dropdown-item" to="#" onClick={e => viewItem(e, item)}>Visualizar</Link> */}
                                                            <Link className="dropdown-item" to="#" onClick={e => statusSwitch(item)} data-toggle="modal" data-target="#modalConfirm" >{item.status == '0' ? 'Desativar' : 'Ativar'}</Link>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td colSpan={4} className="text-center">Nenhum registro encontrado</td>
                                            </tr>
                                        )}
                                        
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div className="modal fade" id="modalConfirm" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            {registro && (
                                <strong>Gostaria de {desstatus} o Tipo de Entidade "{registro.descricao}" ?</strong>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger col-md-3" onClick={confirmStatus} >Sim</button>
                            <button type="button" className="btn btn-outline-dark col-md-3" data-dismiss="modal">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>

            <aside className="control-sidebar control-sidebar-dark">
            </aside>
            <Footer />
        </>
    );
}

export default connect((state) => ({
    sistema: state.sistema,
    usuario: state.usuario
}))(TipoEntidadeConsulta);