import styled from 'styled-components';

import bg from '../../assets/background_login.png';

export const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    margin: 0px!important;
    min-height: 100vh;
    background-image: url(${bg});
    background-size: cover;
    background-position: center;


    .login{
        width: 80%!important;
        input {
            padding: 0.5em 0.8em 0.5em 0.8em;
        }        
    }

    .bg-login{
        min-height: 100vh;
        border-top-left-radius: 60px!important;
        border-bottom-left-radius: 60px!important;
        background-color: white;
        margin: 0px!important
    }
    
    .bt-entrar{
        font-weight: bold!important;
        font-size: 16px!important;       
        padding: 0.3em!important; 
    }

    .bg-image {
        min-height: 100vh;
    }

    

    .login-heading {
        font-weight: 300;
    }

    .btn-login {
        font-size: 0.9rem;
        letter-spacing: 0.05rem;
        padding: 0.75rem 1rem;
        border-radius: 2rem;
    }

    .form-label-group {
        position: relative;
        margin-bottom: 1rem;
    }

    .form-label-group input,
    .form-label-group label {
        height: auto;
        border-radius: 2rem;
    }

    .form-label-group label {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        margin-bottom: 0;
        
        line-height: 1.5;
        color: #495057;
        cursor: text;
        
        border: 1px solid transparent;
        border-radius: .25rem;
        transition: all .1s ease-in-out;
    }

    .form-label-group input::-webkit-input-placeholder {
        color: transparent;
    }

    .form-label-group input:-ms-input-placeholder {
        color: transparent;
    }

    .form-label-group input::-ms-input-placeholder {
        color: transparent;
    }

    .form-label-group input::-moz-placeholder {
        color: transparent;
    }

    .form-label-group input::placeholder {
        color: #777;
    }
   
    .font-subtext {
        font-size: 11px;
        color: #777;
    }
    --input-padding-x: 1.5rem;
        --input-padding-y: 0.75rem;

`;