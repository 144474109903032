import React, {useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import pt from 'date-fns/locale/pt-BR';
import { connect } from 'react-redux';
import {format, startOfDay, startOfToday, isBefore, lastDayOfDecade} from 'date-fns';
import { Editor } from '@tinymce/tinymce-react'; 
import * as yup from 'yup';
import { toast } from 'react-toastify';

import SideBar from '../../components/SideBar';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';

import api from '../../services/api';
import { Link } from 'react-router-dom';
import { locationsAreEqual } from 'history';

function UsuarioView({history, usuario, location}) {
    const schema = yup.object({
        nome: yup.string().min(1).required(),
        email: yup.string().min(1).required(),
        login: yup.string().min(1).required(),
        senha: yup.string().min(1).required(),
        cargo: yup.string().min(1).required(),
        status: yup.string(),
        id_perfil: yup.string().min(1).required(),
        id_entidade: yup.string().min(1).required(),
    });
    const [filtro, setFiltro] = useState(null);
    const date = startOfDay(new Date());
    const [registro, setRegistro] = useState({
        id_usuario: '',
        nome: '',
        email: '',
        login: '',
        senha: '',
        cargo: '',
        status: '',
        id_perfil: 0,
        id_entidade: 0,
    });

    const [perfis, setPerfis] = useState([]);
    const [entidades, setEntidades] = useState([]);

    useEffect(() => {
        
        async function load(){
            const result = await api.get('/entidades', { params: {id_entidade: ''}});
            setEntidades(result.data ? result.data : []);

            const resultPerfil = await api.get('/perfil');
            setPerfis(resultPerfil.data ? resultPerfil.data : null);
        }
        
        load();

        if (location.state)
        {
            setRegistro(location.state.registro);
            if (location.state.filtro)
                setFiltro(location.state.filtro);
        }

        
    }, [])

    const validaUsuario = async (e) => {
        var user = await api.get('/usuario/validar', { params: registro});
        console.log(user);
        if (user.data.length > 0)
        {
            e.target.value = '';
            setRegistro({...registro, login: ''});
            toast.warning('Login ('+registro.login+') já está em uso!');            
        }
    }

    const voltar = (e) => {
        e.preventDefault();
        history.replace('/usuarios', {filtro});

    }
    
    return (
        <>
            <NavBar history={history} />
            <SideBar opcao="usuarios"/>
            <div className="content-wrapper">            
                <section className="content main-content ">
                    <div className="container-fluid">
                        <br />
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/usuarios">Usuário</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Visualizar</li>
                            </ol>
                        </nav>

                        <div className="card">
                            <div className="card-body">
                                <form>
                                    <div className="form-row">
                                        <div className="form-group col-md-8">
                                            <label>Nome *</label>
                                            <input disabled type="text" className="form-control" value={registro.nome} onChange={e => setRegistro({...registro, nome: e.target.value})}/>
                                        </div>  
                                        
                                    </div>  
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label>E-mail *</label>
                                            <input disabled type="text" className="form-control" value={registro.email} onChange={e => setRegistro({...registro, email: e.target.value})}/>
                                        </div>  
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-8">
                                            <label>Entidade *</label>
                                            <select disabled className="form-control" onChange={e => setRegistro({...registro, id_entidade: e.target.value})} value={registro.id_entidade}>
                                                <option value={0} key="todos">Selecione</option>
                                                {entidades.map((item, index) => 
                                                    <option key={index} value={item.id_entidade}>{item.nome}</option>
                                                )}
                                            </select>
                                        </div>  
                                    </div>  
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label>Cargo *</label>
                                            <input disabled type="text" className="form-control" value={registro.cargo} onChange={e => setRegistro({...registro, cargo: e.target.value})}/>
                                        </div>  
                                        <div className="form-group col-md-6">
                                            <label>Perfil *</label>
                                            <select disabled className="form-control" onChange={e => setRegistro({...registro, id_perfil: e.target.value})} value={registro.id_perfil}>
                                                <option value={0}>Selecione</option>
                                                {perfis.map((item, index) => (
                                                    <option key={index} value={item.id_perfil}>{item.nome}</option>
                                                ))}
                                            </select>
                                        </div>  
                                    </div>    
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label>Login</label>
                                            <input type="text" disabled className="form-control" value={registro.login} onBlur={validaUsuario} onChange={e => setRegistro({...registro, login: e.target.value})}/>
                                        </div>                                           
                                    </div> 

                                </form>
                            </div>
                        </div>

                        <hr />
                        <div className="row">
                            <div className="col d-flex flex-row" >
                                <Link className="btn btn-primary col-md-2" to="#" onClick={voltar}>Voltar</Link>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <aside className="control-sidebar control-sidebar-dark">
            </aside>
            <Footer />
        </>
    );
}

export default connect((state) => ({
    sistema: state.sistema,
    usuario: state.usuario
}))(UsuarioView);