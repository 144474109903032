import React, {useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import pt from 'date-fns/locale/pt-BR';
import { connect } from 'react-redux';
import {format, startOfDay, startOfToday, isBefore, lastDayOfDecade} from 'date-fns';
import { Editor } from '@tinymce/tinymce-react'; 
import * as yup from 'yup';
import { toast } from 'react-toastify';

import SideBar from '../../components/SideBar';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';

import api from '../../services/api';
import config from '../../services/config';
import { Link } from 'react-router-dom';

function PublicacaoCadastro({history, usuario, location}) {
    const schema = yup.object({
        titulo: yup.string().min(1).required(),
        descricao: yup.string().min(1).required(),
        data_publicacao: yup.string().min(10).required(),
        id_tipo_publicacao: yup.number().min(1).required(),
        id_tipo_ato: yup.number().min(1).required()
    });

    const date = startOfDay(new Date());
    const [id_tipo_ato, setTipoAto] = useState('');
    const [id_tipo_publicacao, setTipoPublicacao] = useState('');
    const [atos, setAtos] = useState(null);
    const [tipos, setTipos] = useState(null);
    const [titulo, setTitulo] = useState('');
    const [descricao, setDescricao] = useState('');
    const [descricaoInicial, setDescricaoInicial] = useState('');
    const [data_cadastro, setDataCadastro] = useState(format(startOfToday(new Date()), 'dd/MM/yyyy'));
    const [data_publicacao, setDataPublicacao] = useState(null);
    const [edit, setEdit] = useState(location.state ? true : false);
    const [id_publicacao, setIdPublicacao] = useState(null);
    const [id_entidade, setIdEntidade] = useState(usuario.id_entidade);
    const [entidades, setEntidades] = useState(null);

    useEffect(() => {
        
        if (location.state)
        {
            const {publicacao} = location.state;
            console.log(publicacao);
            setTipoAto(publicacao.id_tipo_ato);
            setTipoPublicacao(publicacao.id_tipo_publicacao);
            setTitulo(publicacao.titulo);
            setDescricao(publicacao.descricao);
            setDescricaoInicial(publicacao.descricao);
            setDataCadastro(publicacao.dt_cadastro);
            setDataPublicacao(new Date(publicacao.data_publicacao +' 00:00:00'));
            setDataPublicacaoPick(new Date(publicacao.data_publicacao +' 00:00:00'));
            setIdPublicacao(publicacao.id_publicacao);
        }
    }, [])

    // eslint-disable-next-line
    const [dataPublicacaoPick, setDataPublicacaoPick] = useState(null);

    function inputPublicacaoChange(e){
        setDataPublicacaoPick(e);
        setDataPublicacao(e);
        console.log(e);
    }
    function limpaDataPublicacao(e){
        setDataPublicacaoPick('');
        setDataPublicacao('');
    }
    useEffect(() => {
        async function carregaTabela(){
            const result = await api.get('ato');
            setAtos(result.data);

            const resultTipo = await api.get('tipo');
            setTipos(resultTipo.data);
            console.log(result.data);

            const filtro_ent = {
                nome: '',        
                sigla: '',
                nome_entidade_pai: '',
                id_municipio: '',
                id_tipo_entidade: '',
                status: '',
            };
            const resultEntidades = await api.get('/entidade', {params : filtro_ent});

            setEntidades(resultEntidades.data ? resultEntidades.data : []);
        }

        carregaTabela();
    }, [])


    const gravar = async (e) => {
        e.preventDefault();
        let dados = {...usuario, 
            id_publicacao, 
            id_tipo_ato, 
            titulo, 
            descricao, 
            data_publicacao: data_publicacao ? format(data_publicacao, 'yyyy-MM-dd') : '', 
            id_tipo_publicacao,
            id_entidade
        };

        const valido = await schema.isValid(dados);
        if (!valido)
        {
            toast.warning('Informe todos os campos obrigatórios.');
            return false;
        }
        if (id_publicacao)
        {
            if (dados.id_diario)
                var resultPudate = await api.put('/publicacao/admin', dados);
            else
                var resultPudate = await api.put('/publicacao', dados);
        }
        else
            var result = await api.post('/publicacao', dados);

        toast.success('Registro salvo com sucesso!');
        history.replace('/publicacoes');
    }

    const handleChangeDescricao = (content, editor) => {
        setDescricao(content.level.content);
    }

 
    return (
        <>
            <NavBar history={history} />
            <SideBar opcao="publicacoes"/>
            <div className="content-wrapper">            
                <section className="content main-content ">
                    <div className="container-fluid">
                        <br />
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/publicacoes">Publicações</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Cadastro</li>
                            </ol>
                        </nav>

                        <div className="card">
                            <div className="card-body">
                                <form>
                                    <div className="form-row">                                                       
                                    
                                        <div className="form-group col-md-2">
                                            <label>Data Cadastro</label>
                                            <input type="text" className="form-control" value={data_cadastro} disabled/>
                                        </div>                 
                                        <div className="form-group col-md-2">
                                            <label >Data da Publicação *</label>
                                            <DatePicker
                                                name="data"
                                                dateFormat="dd/MM/yyyy"
                                                minDate={new Date()}
                                                selected={dataPublicacaoPick}
                                                onChange={inputPublicacaoChange}
                                                onClickOutside={limpaDataPublicacao}
                                                className="form-control"
                                                locale={pt} />
                                        </div>                                
										<div className="form-group col-md-3">
                                            <label >Formato de Publicação *</label>
                                            <select className="form-control" value={id_tipo_publicacao} onChange={e => setTipoPublicacao(e.target.value)}>
                                                <option value="">...</option>
                                                {tipos && tipos.map((item, index) => 
                                                    <option key={index} value={item.id_tipo_publicacao}>{item.descricao}</option>
                                                )}
                                            </select>
                                        </div>  
                                        <div className="form-group col-md-3">
                                            <label >Tipo do ATO *</label>
                                            <select className="form-control" value={id_tipo_ato} onChange={e => setTipoAto(e.target.value)}>
                                                <option value="">...</option>
                                                {atos && atos.map((item, index) => 
                                                    <option key={index} value={item.id_tipo_ato}>{item.nome}</option>
                                                )}
                                            </select>
                                        </div>                                
                                    </div>
                                    {/* {('1,2'.indexOf(usuario.id_perfil) > -1) && (
                                    <div className="form-row">
                                        <div className="form-group col-md-5">
                                            <label>Entidade</label>
                                            <select className="form-control" onChange={e => setIdEntidade(e.target.value)} value={id_entidade}>
                                                {entidades && entidades.map(item => (
                                                    <option key={item.id_entidade} value={item.id_entidade}>{item.nome}</option>
                                                ))}
                                            </select>
                                        </div>    
                                    </div>
                                    )} */}
                                    <div className="form-row">
                                        <div className="form-group col-md-10">
                                            <label >Titulo *</label>
                                            <input type="text" className="form-control" value={titulo} onChange={e => setTitulo(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-10">
                                            <Editor
                                                apiKey="21c4cvqt6brn2olq69wuah32od4hbl6td6lbrtzbsnk0473c"
                                                initialValue={descricaoInicial}
                                                init={{
                                                    height: 340,
                                                    menubar: false,
                                                    menubar: 'file edit view insert format tools table',  
                                                    plugins: [
                                                        'directionality advlist autolink lists link image charmap print preview anchor',
                                                        'pagebreak quickbars imagetools searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar: 'undo redo | formatselect | ' +
                                                    'bold italic backcolor | alignleft aligncenter ' +
                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                    'removeformat | image | table | help',
                                                    content_style: 'body { font-family:Verdana,Arial,sans-serif; font-size: 14px }',
                                                    // plugins: [
                                                    //     'advlist autolink lists link image', 
                                                    //     'charmap print preview anchor help',
                                                    //     'searchreplace visualblocks code',
                                                    //     'insertdatetime media table paste wordcount'
                                                    // ],
                                                    // toolbar:
                                                    //     'undo redo | formatselect | bold italic | \
                                                    //     alignleft aligncenter alignright | \
                                                    //     bullist numlist outdent indent | image | help',
                                                    selector: 'textarea',
                                                    language: 'pt_BR',
                                                    file_picker_types: 'image',
                                                    image_uploadtab: true,
                                                    images_upload_url: config.baseURL + 'upload',
                                                    images_upload_base_path: '',
                                                }}
                                                onChange={handleChangeDescricao}
                                            />                                   
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <hr />
                        <div className="row">
                            <div className="col d-flex flex-row" >
                                <button className="btn btn-primary mr-4 col-md-2" onClick={gravar}>Salvar</button>
                                <Link className="btn btn-primary col-md-2" to="/publicacoes">Voltar</Link>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <aside className="control-sidebar control-sidebar-dark">
            </aside>
            <Footer />
        </>
    );
}

export default connect((state) => ({
    sistema: state.sistema,
    usuario: state.usuario
}))(PublicacaoCadastro);