import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import { connect, useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import pt from 'date-fns/locale/pt-BR';
import {format, startOfDay, isBefore, startOfToday} from 'date-fns';
import * as $ from 'jquery';

import SideBar from '../../components/SideBar';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import api from '../../services/api';
import config from '../../services/config';
import { Link } from 'react-router-dom';

function DiarioConsulta({history}) {

    const date = startOfDay(new Date());
    const [diario, setDiario] = useState('');    
    const [edicao, setEdicao] = useState('');    
    const [lista, setLista] = useState(null);
    const [desstatus, setDesStatus] = useState('');
    const [endereco, setEndereco] = useState('');
    const [dataPublicacao, setDataPublicacao] = useState(new Date());
    const [horaPublicacao, setHoraPublicacao] = useState(new Date());
    const [filtro, setFiltro] = useState({
        data_publicacao: '',        
        dtpublicacao: null,
        data_cadastro: '',
        dtcadastro: null,
        palavra_chave: '',
        situacao: '',
        edicao: '',
        tipo_diario: '',
    })

    // eslint-disable-next-line
    const [dataCadastroPick, setDataCadastroPick] = useState(null);
    // eslint-disable-next-line
    const [dataPublicacaoPick, setDataPublicacaoPick] = useState(date);
  
    function inputCadastroChange(e){
        if (e)
        {
            setDataCadastroPick(e);
            setFiltro({...filtro, dtcadastro: e, data_cadastro: format(e, 'yyyy-MM-dd')});
        }else{
            setDataCadastroPick('');
            setFiltro({...filtro, dtcadastro: null, data_cadastro: ''});
        }
    }
    function limpaDataCadastro(e){
        setDataCadastroPick('');
        setFiltro({...filtro, dtcadastro: null, data_cadastro: ''});
    }

    function inputPublicacaoChange(e){
        setDataPublicacaoPick(e);
        setFiltro({...filtro, dtpublicacao: e, data_publicacao: format(e, 'yyyy-MM-dd')});
    }
    function limpaDataPublicacao(e){
        setDataPublicacaoPick('');
        setFiltro({...filtro, dtpublicacao: null, data_publicacao: ''});
    }

    function toggleDrop(e) {
        e.preventDefault();
        return false;
    }

    useEffect(() => {
        async function carregaTabela(){
            const result = await api.get('/diario', {params : filtro});
            console.log(result.data);

            setLista(result.data ? result.data : null);
        }

        carregaTabela();
    }, [])

    const consutlar = async (e) => {
        e.preventDefault();
        const result = await api.get('/diario', {params : filtro});
        console.log(result.data);
        
        setLista(result.data ? result.data : null);
    }
        
    function viewItem(e, item){
        e.preventDefault();
        history.push('/publicacoes_view', {publicacao: item});
    }

    function editItem(e, diario){
        e.preventDefault();
        history.push('/diario_editar', {diario_edit: diario});
    }

    function publicarDiario(e, diario){
        e.preventDefault();
        setDiario(diario);
        window.$('#modalPublicar').modal('show');
        // history.push('/diario_editar', {diario_edit: diario});
    }

    async function confirmStatus(){
        const novostatus = diario.status === 1 ? 0 : 1;
        const desstatus = novostatus === 1 ? 'Desativado' : 'Ativado';

        const del = await api.put('/diario/status', {id_publicacao: diario.id_publicacao, status: novostatus});
        if (del.data)
        {
            toast.success(`Diário ${desstatus} com sucesso`);
        }

        const result = await api.get('/diario', {params : filtro});
        setLista(result.data);
        window.$('#modalConfirm').modal('toggle');

    }

    function statusSwitch(diario){

        const novostatus = diario.status === 1 ? 0 : 1;
        const desstatus = novostatus === 1 ? 'DESATIVAR' : 'ATIVAR';
        setDesStatus(desstatus);
        setDiario(diario);
    }

    function inputDataPulicacaoChange(e){
        setDataPublicacao(e);
    }

    function inputHoraPulicacaoChange(e){
        setHoraPublicacao(e);
    }

    const confirmaPublicacao = async(e) =>{
        e.preventDefault();

        const consulta = await api.get('/diario/day', {params: {
                data_publicacao: format(dataPublicacao, 'yyyy-MM-dd'),
            }
        });
        
        console.log(consulta.data);

        let tipo_diario = 0;
        if (consulta.data)
            tipo_diario = 1;

        const result = await api.put('/diario', {
            endereco,
            data_publicacao: format(dataPublicacao, 'yyyy-MM-dd') + ' ' + format(horaPublicacao, 'HH:mm') + ':00',
            id_diario: diario.id_diario,
            tipo_diario
        });
        
        toast.success('Agendamento da Publicação efetuada com sucesso!');

        const resultConsulta = await api.get('/diario', {params : filtro});        
        setLista(resultConsulta.data ? resultConsulta.data : null);
        window.$('#modalPublicar').modal('toggle');
    }

    return (
        <>
            <NavBar history={history} />
            <SideBar opcao="diario"/>
            <div className="content-wrapper">            
                <section className="content main-content">
                    <div className="container-fluid">
                        <br />
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">Diários</li>
                            </ol>
                        </nav>
                        <div className="card">
                            <div className="card-body">

                                <form className={config.isMobile ? '' : ''}>
                                    <div className="form-group row">
                                        <div className={'form-group col-md-2'}>
                                            <label>Nº Edição</label>
                                            <input type="text" className="form-control" value={filtro.edicao} onChange={e => setFiltro({...filtro, edicao: e.target.value})} />
                                        </div>
                                   
                                        <div className={'form-group col-md-2'}>
                                            <label>Data do Cadastro</label>
                                            <DatePicker
                                                name="data"
                                                dateFormat="dd/MM/yyyy"
                                                selected={filtro.dtcadastro}
                                                onChange={inputCadastroChange}
                                                onClickOutside={limpaDataCadastro}
                                                className="form-control"
                                                locale={pt} />
                                        </div>
                                        <div className={'form-group col-md-2'}>
                                            <label>Data da Publicação</label>
                                            <DatePicker
                                                name="data"
                                                dateFormat="dd/MM/yyyy"
                                                selected={filtro.dtpublicacao}
                                                onChange={inputPublicacaoChange}
                                                onClickOutside={limpaDataPublicacao}
                                                className="form-control"
                                                locale={pt} />
                                        </div>
                                        <div className={'form-group col-md-4'}>
                                            <label>Palavra Chave</label>
                                            <input type="text" className="form-control" placeholder="" 
                                            value={filtro.palavra_chave} onChange={e => setFiltro({...filtro, palavra_chave: e.target.value})} />
                                        </div>
                                        
                                    </div>

                                    <div className="form-group row"> 
                                        <div className={'form-group col-md-2'}>
                                            <label>Situação</label>
                                            <select className="form-control" onChange={e => setFiltro({...filtro, situacao: e.target.value})} value={filtro.situacao}>
                                                <option value="">Todos</option>
                                                <option value="0">Pendente</option>
                                                <option value="1">Publicado</option>
                                            </select>
                                        </div>                                                                              

                                        <div className={'form-group col-md-2'}>
                                            <label>Tipo de Diário</label>
                                            <select className="form-control" onChange={e => setFiltro({...filtro, tipo_diario: e.target.value})} value={filtro.tipo_diario}>
                                                <option value="">Todos</option>
                                                <option value="0">Ordinário</option>
                                                <option value="1">Extraordinário</option>
                                            </select>
                                        </div>                                                                              
                                    </div>

                                    <div className="form-row">
                                        <div className={'form-group col'}>
                                            <button className="btn btn-primary col-md-3 col-sm-6" onClick={consutlar}>Consultar</button>
                                        </div>
                                        <div className={'form-group col text-right'}>
                                            <Link className="btn btn-success col-md-3 col-sm-6" to="/diario_cad">Novo</Link>
                                        </div>
                                    </div>
                                </form>
                            
                                <hr />
                                <table className="table table-striped">
                                    <thead >
                                        <tr>
                                            <th style={{width: 94}} >Nº EDIÇÃO</th>
                                            <th style={{width: 110}} >DATA CADASTRO</th>
                                            <th scope="col">PUBLICADO POR</th>
                                            <th style={{width: 110}} >DATA PUBLICAÇÃO</th>
                                            <th style={{width: 100}} >TIPO</th>
                                            <th style={{width: 100}} scope="col">SITUAÇÃO</th>
                                            <th style={{width: 100}}>OPÇÕES</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {lista ? lista.map((item, index) => (
                                            <tr key={index}>
                                                <th className="text-center">
                                                    {("000" + item.id_diario).slice(-3)}/{item.dt_cadastro.substr(6,4)}
                                                </th>
                                                <th style={{width: 110}} className="text-center">{item.dt_cadastro}</th>
                                                <td>{item.nome_usuario}</td>
                                                <td className="text-center">{item.dt_publicacao}</td>
                                                <td>{item.data_publicacao && (item.tipo_diario == '0' ? 'Ordinário' : 'Extraordinário')}</td>
                                                <td>{item.situacao_des}</td>
                                                <td className="text-center" style={{width: 80}}>
                                                    <div className="dropdown dropleft">
                                                        <button onClick={toggleDrop} id="dropMenu" className="btn btn-secondary btn-sm" type="button"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="nav-icon fas fa-list"></i>
                                                        </button>

                                                        <div className="dropdown-menu" aria-labelledby="dropMenu">
                                                            {/* <a className="dropdown-item" to="#" onClick={(e) => viewItem(e, item)}>Detalhes</a> */}
                                                            {item.situacao == 0 && (
                                                                <Link className="dropdown-item" to="#" onClick={(e) => editItem(e, item)}>Editar</Link>
                                                            )}
                                                            <a className="dropdown-item" href={config.baseURL + 'pdf.php?id_diario=' + item.id_diario} target="_blank">Gerar Diário</a>
                                                            {item.situacao == 0 && (
                                                                <Link className="dropdown-item" to="#" onClick={(e) => publicarDiario(e, item)} >Publicar na Internet</Link>
                                                            )}
                                                            {/* <a className="dropdown-item" to="#" onClick={() => statusSwitch(item)} data-toggle="modal" data-target="#modalConfirm" >{item.status === '0' ? 'Desativar' : 'Ativar'}</a> */}
                                                            {item.situacao == 1 && (
                                                                <a className="dropdown-item" href={item.endereco} target="_blank">Visualizar Publicação</a>
                                                            )}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td colSpan={6} className="text-center">Nenhum registro encontrado</td>
                                            </tr>
                                        )}
                                            
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div className="modal fade" id="modalConfirm" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            {diario && (
                                <strong>Gostaria de {desstatus} o Diário "{diario.titulo}" ?</strong>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger col-md-3" onClick={confirmStatus} >Sim</button>
                            <button type="button" className="btn btn-outline-dark col-md-3" data-dismiss="modal">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalPublicar" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            {diario && (
                            <form>
                                <div className={'form-group col-md-6'}>
                                    <label>Data para Publicação</label>
                                    <DatePicker
                                        name="data"
                                        dateFormat="dd/MM/yyyy"
                                        minDate={new Date()}
                                        selected={dataPublicacao}
                                        onChange={inputDataPulicacaoChange}
                                        className="form-control"
                                        locale={pt} />
                                </div>
                                <div className={'form-group col-md-4'}>
                                    <label>Hora para Publicação</label>
                                    <DatePicker
                                        name="data"
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={30}
                                        timeCaption="Hora"
                                        dateFormat="HH:mm"
                                        selected={horaPublicacao}
                                        onChange={inputHoraPulicacaoChange}
                                        className="form-control"
                                        locale={pt} />
                                </div>
                                <div className="form-group col-md-12">
                                    <label>Link (Google Drive)</label>
                                    <input type="text" className="form-control" value={endereco} onChange={e => setEndereco(e.target.value)}/>
                                    {/* <small className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                </div>
                            </form>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger col-md-3" onClick={confirmaPublicacao} >Publicar</button>
                            <button type="button" className="btn btn-outline-dark col-md-3" data-dismiss="modal">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
            <aside className="control-sidebar control-sidebar-dark">
            </aside>
            <Footer />
        </>
    );
}

export default connect((state) => ({
    sistema: state.sistema,
    usuario: state.usuario
}))(DiarioConsulta);