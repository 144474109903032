import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import { connect, useDispatch, useSelector } from 'react-redux';
import pt from 'date-fns/locale/pt-BR';
import {format, startOfDay, isBefore, startOfToday} from 'date-fns';
import * as $ from 'jquery';

import SideBar from '../../components/SideBar';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import api from '../../services/api';
import config from '../../services/config';
import { Link } from 'react-router-dom';

function UsuarioConsulta({history, location}) {

    const date = startOfDay(new Date());
    const [usuario, setUsuario] = useState(null); 
    const [lista, setLista] = useState(null);
    const [perfis, setPerfis] = useState([]);
    const [entidades, setEntidades] = useState([]);
    const [desstatus, setDesStatus] = useState('');
    const [filtro, setFiltro] = useState({
        nome: '',        
        email: '',
        login: '',
        id_entidade: '',
        id_perfil: '',
        status: '',
    })


    function toggleDrop(e) {
        e.preventDefault();
        return false;
    }

    useEffect(() => {
        let filtro_ = {...filtro};
        if (location.state && location.state.filtro)
        {
            console.log(location.state);
           filtro_ = location.state.filtro;
            setFiltro(location.state.filtro);
        }

        async function carregaTabela(){
            const result = await api.get('/entidades', { params: {id_entidade: (location.state && location.state.registro) ? location.state.registro.id_entidade: ''}});
            setEntidades(result.data ? result.data : []);

            const resultPerfil = await api.get('/perfil');
            setPerfis(resultPerfil.data ? resultPerfil.data : []);

            const resultUsuarios = await api.get('/usuarios', {params : filtro_});
            setLista(resultUsuarios.data ? resultUsuarios.data : null);
        }

        carregaTabela();
    }, []);

    const consutlar = async (e) => {
        e.preventDefault();
        const result = await api.get('/usuarios', {params : filtro});
        setLista(result.data ? result.data : null);
    }
        
    function viewItem(e, item){
        e.preventDefault();
        history.push('/usuarios_view', {registro: item, filtro});
    }

    function editItem(e, item){
        e.preventDefault();
        history.push('/usuarios_cad', {registro: item, filtro});
    }

    async function confirmStatus(){
        const novostatus = usuario.status === 1 ? 0 : 1;
        const desstatus = novostatus === 1 ? 'Desativado' : 'Ativado';

        const del = await api.put('/usuario/status', {id_usuario: usuario.id_usuario, status: novostatus});
        const result = await api.get('/usuarios', {params : filtro});
        window.$('#modalConfirm').modal('toggle');
        toast.success(`Usuário ${desstatus} com sucesso`);
        setLista(result.data);
    }

    function statusSwitch(item){

        const novostatus = item.status === 1 ? 0 : 1;
        const desstatus = novostatus === 1 ? 'DESATIVAR' : 'ATIVAR';
        setDesStatus(desstatus);
        setUsuario(item);
    }

    const novo = (e) =>{
        e.preventDefault();
        history.push('/usuarios_cad', {filtro});

    }
    
    return (
        <>
            <NavBar history={history} />
            <SideBar opcao="usuarios"/>
            <div className="content-wrapper">            
                <section className="content main-content">
                    <div className="container-fluid">
                        <br />
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">Cadastrar Usuário</li>
                            </ol>
                        </nav>
                        <div className="card">
                            <div className="card-body">
                                <form>
                                    <div className="form-row">
                                        <div className="form-group col-md-5">
                                            <label>Nome</label>
                                            <input type="text" className="form-control" value={filtro.nome} onChange={e => setFiltro({...filtro, nome: e.target.value})}/>
                                        </div>  

                                        <div className="form-group col-md-4">
                                            <label>E-mail</label>
                                            <input type="text" className="form-control" value={filtro.email} onChange={e => setFiltro({...filtro, email: e.target.value})}/>
                                        </div>  

                                    </div>

                                    <div className="form-row">
                                        <div className="form-group col-md-5">
                                            <label>Entidade</label>
                                            <select className="form-control" onChange={e => setFiltro({...filtro, id_entidade: e.target.value})} value={filtro.id_entidade}>
                                                <option value="" key="todos">Todos</option>
                                                {entidades.map((item, index) => 
                                                    <option key={index} value={item.id_entidade}>{item.nome}</option>
                                                )}
                                            </select>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label>Status</label>
                                            <select className="form-control" onChange={e => setFiltro({...filtro, status: e.target.value})} value={filtro.status}>
                                                <option value="">Todos</option>
                                                <option value="0">Ativo</option>
                                                <option value="1">Desativado</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className={'form-group col'}>
                                            <button className="btn btn-primary col-md-3 col-sm-6" onClick={consutlar}>Consultar</button>
                                        </div>
                                        <div className={'form-group col text-right'}>
                                            <Link className="btn btn-success col-md-3 col-sm-6" to="#" onClick={novo}>Novo</Link>
                                        </div>
                                    </div>
                                </form>
                            
                                <hr />
                                <table className="table table-striped">
                                    <thead >
                                        <tr>
                                            <th style={{width: 180}} >NOME</th>
                                            <th>E-MAIL</th>
                                            <th>LOGIN</th>
                                            <th>CARGO</th>
                                            <th>ENTIDADE</th>
                                            <th>STATUS</th>
                                            <th></th>
                                        </tr>
                                    </thead> 
                                    <tbody>
                                        {lista ? lista.map((item, index) => (
                                            <tr key={index}>
                                                <th>{item.nome}</th>
                                                <th>{item.email}</th>
                                                <td>{item.login}</td>
                                                <td>{item.cargo}</td>
                                                <td>{item.nome_entidade}</td>
                                                <td className="text-center" style={{width: 80}}>{item.status == '0' ? 'Ativo' : 'Inativo'}</td>
                                                <td className="text-center" style={{width: 60}}>
                                                    <div className="dropdown dropleft">
                                                        <button onClick={toggleDrop} id="dropMenu" className="btn btn-secondary btn-sm" type="button"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="nav-icon fas fa-list"></i>
                                                        </button>

                                                        <div className="dropdown-menu" aria-labelledby="dropMenu">
                                                            <Link className="dropdown-item" to="#" onClick={e => editItem(e, item)} >Editar</Link>
                                                            <Link className="dropdown-item" to="#" onClick={e => viewItem(e, item)}>Visualizar</Link>
                                                            <Link className="dropdown-item" to="#" onClick={e => statusSwitch(item)} data-toggle="modal" data-target="#modalConfirm" >{item.status == '0' ? 'Desativar' : 'Ativar'}</Link>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td colSpan={9} className="text-center">Nenhum registro encontrado</td>
                                            </tr>
                                        )}
                                        
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div className="modal fade" id="modalConfirm" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            {usuario && (
                                <strong>Gostaria de {desstatus} o Usuário "{usuario.nome}" ?</strong>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger col-md-3" onClick={confirmStatus} >Sim</button>
                            <button type="button" className="btn btn-outline-dark col-md-3" data-dismiss="modal">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>

            <aside className="control-sidebar control-sidebar-dark">
            </aside>
            <Footer />
        </>
    );
}

export default connect((state) => ({
    sistema: state.sistema,
    usuario: state.usuario
}))(UsuarioConsulta);