import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import config from '../../services/config';

import logo from '../../assets/logo_white.png';
import * as $ from 'jquery';

function SideBar({history, usuario, opcao}) {

    const menuDiario = () => {
        // console.log(window.$("#treeview-diario").css('display'));
        // var display = window.$("#treeview-diario").css('display');
        // window.$("#treeview-diario").css('display',display=='block' ? 'none' : 'block');
        window.$("#treeview-diario").toggleClass("showme");
    }

    const menuTabelas = () => {
        window.$("#treeview-tabelas").toggleClass("showme");
    }
    const [user, serUser] = useState(null);


    useEffect(() => {
        const user = localStorage.getItem('dom.usuario');
        serUser(JSON.parse(user));
    }, []);

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <Link to="/home" className="brand-link text-center">
            <span className="brand-text "><img src={logo} height={160} className="img-fluid" /></span>
        </Link>

        <div className="user-panel mt-3 pb-3 mb-3 d-flex">            
            <div className="info">
                <b className="brand-text text-white">{usuario.nome}</b><br/>
                <small className="brand-text text-white">{usuario.cargo}</small>
            </div>
        </div>

        <nav className="mt-2">
            {user && (
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">

                <li className="nav-item">
                    <Link to="/publicacoes" className={'nav-link ' + (opcao === 'publicacoes' ? 'active' : '')}>
                        <i className="nav-icon fas fa-file"></i>
                        <p>Publicações</p>
                    </Link>
                </li>

                {'1,2,5'.indexOf(user.id_perfil) > -1 ? (
                    <li className="nav-item">
                    <a href="#" className={'nav-link'} onClick={menuDiario}>
                        <i className="nav-icon fas fa-file-alt"></i>
                        <p>
                            Diário 
                        </p>
                    </a>

                    <ul className="nav nav-treeview" id="treeview-diario" >
                        <li className="nav-item">
                            <Link to="/diario" className={'nav-link ' + (opcao === 'diario' ? 'active' : '')}>
                                <i className="nav-icon fas fa-folder-open"></i>
                                <p>Listar Diários</p>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <a href="/capa" className="nav-link">
                            <i className="far fa-file-powerpoint nav-icon"></i>
                            <p>Capa Principal</p>
                            </a>
                        </li>                            
                    </ul>
                </li>
                    
                ) : (
                    
                    <li className="nav-item">
                        <Link to="/" onClick={e => e.preventDefault()} className={'menu-disabled nav-link ' + (opcao == 'entidades' ? 'active' : '')}>
                            <i className="nav-icon fas fa-newspaper"></i>
                            <p>Diário</p>
                        </Link>
                    </li>
                )}
                
                <li className="nav-item">
                    {'1,2'.indexOf(usuario.id_perfil) > -1 ? (
                        <Link to="/usuarios" className={'nav-link ' + (opcao == 'usuarios' ? 'active' : '')}>
                        <i className="nav-icon fas fa-users"></i>
                        <p>Usuários</p>
                        </Link>
                    ):(
                        <Link to="/" onClick={e => e.preventDefault()}  className={'menu-disabled nav-link ' + (opcao == 'usuarios' ? 'active' : '')}>
                        <i className="nav-icon fas fa-users"></i>
                        <p>Usuários</p>
                        </Link>
                    )}
                </li>

                <li className="nav-item">
                    {'1,2'.indexOf(usuario.id_perfil) > -1 ? (
                        <Link to="/anuncio"   className={'nav-link ' + (opcao == 'anuncio' ? 'active' : '')}>
                            <i className="nav-icon fas fa-bullhorn"></i>
                            <p>Anúncio</p>
                        </Link>
                    ) : (
                        <Link to="/" onClick={e => e.preventDefault()} className={'menu-disabled nav-link ' + (opcao === 'anuncio' ? 'active' : '')}>
                            <i className="nav-icon fas fa-bullhorn"></i>
                            <p>Anúncio</p>
                        </Link>
                    )}
                </li>

                <li className="nav-item">
                    {'1,2'.indexOf(usuario.id_perfil) > -1 ? (
                        <Link to="/entidades" className={'nav-link ' + (opcao == 'entidades' ? 'active' : '')}>
                            <i className="nav-icon fas fa-city"></i>
                            <p>Entidades</p>
                        </Link>
                    ):(
                        <Link to="/" onClick={e => e.preventDefault()} className={'menu-disabled nav-link ' + (opcao == 'entidades' ? 'active' : '')}>
                            <i className="nav-icon fas fa-city"></i>
                            <p>Entidades</p>
                        </Link>
                    )}
                </li>
                
                {/*                 
                <li className="nav-item">
                    <Link to="/" onClick={e => e.preventDefault()} className={'menu-disabled nav-link ' + (opcao == 'anuncios' ? 'active' : '')}>
                    <i className="nav-icon fas fa-comments-dollar"></i>
                    <p>
                        Anuncios 
                    </p>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link to="/" onClick={e => e.preventDefault()} className={'menu-disabled nav-link ' + (opcao == 'configuracoes' ? 'active' : '')}>
                    <i className="nav-icon fas fa-cog"></i>
                    <p>
                        Configurações 
                    </p>
                    </Link>
                </li> 
                */}

                {'1,2'.indexOf(usuario.id_perfil) > -1 && (
                    <li className="nav-item">
                        <a href="#" className={'nav-link'} onClick={menuTabelas}> 
                            <i className="nav-icon fas fa-coins"></i>
                            <p>
                                Tabelas de Apoio 
                            </p>
                        </a>
                        <ul className="nav nav-treeview" id="treeview-tabelas">
                            <li className="nav-item">
                                <Link to="/atos" className={'nav-link ' + (opcao == 'ato' ? 'active' : '')}>
                                <i className="fas fa-book nav-icon"></i>
                                <p>Tipos dos Atos</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <a href="/tiposentidade" className={'nav-link ' + (opcao == 'tiposentidade' ? 'active' : '')}>
                                <i className="fas fa-book nav-icon"></i>
                                <p>Tipo de Entidade</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/certificado" className={'nav-link ' + (opcao == 'certificado' ? 'active' : '')}>
                                <i className="fas fa-file-signature nav-icon"></i>
                                <p>Certificado</p>
                                </a>
                            </li>
                        </ul>
                    </li>
                )}

                <li className="nav-item">
                    <a href="/alterasenha" className={'nav-link ' + (opcao == 'alterasenha' ? 'active' : '')}>
                    <i className="fas fa-key nav-icon"></i>
                    <p>Alterar Senha</p>
                    </a>
                </li>
            </ul>
            )}
        </nav>
        <div className="" style={{
               width: '100%',
               position: 'absolute',
               bottom: 0,
               textAlign: 'center',
               color: '#FFFFFF',
               fontSize: 12,
               padding: 10,
        }}>
          Versão {config.versao}
        </div>
    </aside>
  );
}

export default connect((state) => ({
    sistema: state.sistema,
    usuario: state.usuario
}))(SideBar);