import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import SideBar from '../../components/SideBar';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';

import api from '../../services/api';
import { Link } from 'react-router-dom';

function EntidadeView({history, usuario, location}) {
   
    const [registro, setRegistro] = useState({
        id_entidade: '',
        nome: '',
        sigla: '',
        nome_responsavel: '',
        cargo_responsavel: '',
        contato: '',
        id_usuario: usuario.id_usuario,
        id_municipio: '',
        id_tipo_entidade: '',
        id_entidade_pai: '',
    });

    const [municipios, setMunicipios] = useState([]);
    const [tiposentidades, setTiposEntidades] = useState([]);
    const [entidades, setEntidades] = useState([]);
    const [filtro, setFiltro] = useState(null);

    useEffect(() => {
        
        async function load(){
            const result = await api.get('/entidades', { params: {id_entidade: location.state.registro.id_entidade}});
            setEntidades(result.data ? result.data : []);

            const resultMunicipios = await api.get('/municipio');
            setMunicipios(resultMunicipios.data ? resultMunicipios.data : null);

            const resultTipos = await api.get('/tipo_entidade');
            setTiposEntidades(resultTipos.data ? resultTipos.data : null);
        }
        
        load();

        if (location.state)
        {
            console.log(location.state.registro);
            let reg = location.state.registro;
            reg.id_entidade_pai = reg.id_entidade_pai ? reg.id_entidade_pai : '';
            setRegistro(reg);
            if (location.state.filtro)
                setFiltro(location.state.filtro);
        }

        
    }, [])

    const voltar = (e) => {
        e.preventDefault();
        history.replace('/entidades', {filtro});
    }

    return (
        <>
            <NavBar history={history} />
            <SideBar opcao="entidades"/>
            <div className="content-wrapper">            
                <section className="content main-content ">
                    <div className="container-fluid">
                        <br />
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/entidades">Entidades</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Visualização</li>
                            </ol>
                        </nav>

                        <div className="card">
                            <div className="card-body">
                            <form>
                                    <div className="form-row">
                                        <div className="form-group col-md-8">
                                            <label>Nome *</label>
                                            <input type="text" className="form-control" disabled value={registro.nome} onChange={e => setRegistro({...registro, nome: e.target.value})}/>
                                        </div>  
                                        <div className="form-group col-md-3">
                                            <label>Sigla *</label>
                                            <input type="text" className="form-control" disabled value={registro.sigla} onChange={e => setRegistro({...registro, sigla: e.target.value})}/>
                                        </div>  
                                    </div>  
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label>Nome Responsável *</label>
                                            <input type="text" className="form-control" disabled value={registro.nome_responsavel} onChange={e => setRegistro({...registro, nome_responsavel: e.target.value})}/>
                                        </div>  
                                        <div className="form-group col-md-5">
                                            <label>Cargo Responsável *</label>
                                            <input type="text" className="form-control" disabled value={registro.cargo_responsavel} onChange={e => setRegistro({...registro, cargo_responsavel: e.target.value})}/>
                                        </div>  
                                    </div>    
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label>Contato</label>
                                            <input type="text" className="form-control" disabled value={registro.contato} onChange={e => setRegistro({...registro, contato: e.target.value})}/>
                                        </div>  
                                    </div> 
                                    <div className="form-row">
                                        <div className="form-group col-md-8">
                                            <label>Entidade Pai</label>
                                            <select className="form-control" disabled onChange={e => setRegistro({...registro, id_entidade_pai: e.target.value})} value={registro.id_entidade_pai}>
                                                <option value="" key="todos">Nenhuma</option>
                                                {entidades.map((item, index) => 
                                                    <option key={index} value={item.id_entidade}>{item.nome}</option>
                                                )}
                                            </select>
                                        </div>  
                                    </div>  

                                    <div className="form-row">                                                                          
                                        <div className="form-group col-md-6">
                                            <label>Município *</label>
                                            <select className="form-control" disabled onChange={e => setRegistro({...registro, id_municipio: e.target.value})} value={registro.id_municipio}>
                                                <option value="">Selecione</option>
                                                {municipios.map((item, index) => (
                                                    <option key={index} value={item.id_municipio}>{item.nome}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-row">                                                                          
                                        <div className="form-group col-md-4">
                                            <label>Seção *</label>
                                            <select className="form-control" disabled onChange={e => setRegistro({...registro, secao: e.target.value})} value={registro.secao}>
                                                <option value="1">Seção 1</option>
                                                <option value="2">Seção 2</option>
                                                <option value="3">Seção 3</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-row">                                                                          
                                        <div className="form-group col-md-4">
                                            <label>Tipo *</label>
                                            <select className="form-control" disabled onChange={e => setRegistro({...registro, id_tipo_entidade: e.target.value})} value={registro.id_tipo_entidade}>
                                                <option value="" key="todos">Selecione</option>
                                                {tiposentidades.map((item, index) => (
                                                    <option key={index} value={item.id_tipo_entidade}>{item.descricao}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <hr />
                        <div className="row">
                            <div className="col d-flex flex-row" >
                                <Link className="btn btn-primary col-md-2" to="#" onClick={voltar}>Voltar</Link>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <aside className="control-sidebar control-sidebar-dark">
            </aside>
            <Footer />
        </>
    );
}

export default connect((state) => ({
    sistema: state.sistema,
    usuario: state.usuario
}))(EntidadeView);