import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import Login from './pages/Login';
import Home from './pages/Home';
import PublicacaoConsulta from './pages/PublicacaoConsulta';
import PublicacaoCadastro from './pages/PublicacaoCadastro';
import PublicacaoView from './pages/PublicacaoView';
import DiarioConsulta from './pages/DiarioConsulta';
import DiarioCadastro from './pages/DiarioCadastro';
import Page404 from "./pages/Page404";
import EntidadeConsulta from "./pages/EntidadeConsulta";
import EntidadeCadastro from "./pages/EntidadeCadastro";
import EntidadeView from "./pages/EntidadeView";
import UsuarioConsulta from "./pages/UsuarioConsulta";
import UsuarioCadastro from "./pages/UsuarioCadastro";
import AtoConsulta from "./pages/AtoConsulta";
import AtoCadastro from "./pages/AtoCadastro";
import TipoEntidadeConsulta from "./pages/TipoEntidadeConsulta";
import TipoEntidadeCadastro from "./pages/TipoEntidadeCadastro";
import CapaCadastro from "./pages/CapaCadastro";
import AnuncioConsulta from "./pages/AnuncioConsulta";
import AnuncioCadastro from "./pages/AnuncioCadastro";
import DiarioEditar from "./pages/DiarioEditar";
import PageSair from "./pages/PageSair";
import UsuarioView from "./pages/UsuarioView";
import Certificado from "./pages/Certificado";
import AlteraSenha from "./pages/AlteraSenha";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={props => (
      localStorage.getItem('dom.usuario') ? (
        <Component {...props} />
      ) : (
          <Redirect to={`/`} />
        )
    )} />
  )
}

const LoginRoute = ({ component: Component, ...rest }) => {
  let usuario = localStorage.getItem('dom.usuario');
  if (usuario){
    usuario = JSON.parse(usuario);
  }
  return (
    <Route {...rest} render={props => (
      usuario ? (
        <Redirect to={'/home'} />        
      ) : (
        <Component {...props} />
        )
    )} />
  )
}


const Routes = () => (
  <BrowserRouter>
    <Switch>
      <PrivateRoute path="/home" component={Home}/>
      <PrivateRoute path="/publicacoes" component={PublicacaoConsulta} />
      <PrivateRoute path="/publicacoes_cad" component={PublicacaoCadastro} />
      <PrivateRoute path="/publicacoes_view" component={PublicacaoView} />
      
      <PrivateRoute path="/diario" component={DiarioConsulta} />
      <PrivateRoute path="/diario_cad" component={DiarioCadastro} />
      <PrivateRoute path="/diario_editar" component={DiarioEditar} />
      
      <PrivateRoute path="/entidades" component={EntidadeConsulta} />
      <PrivateRoute path="/entidades_cad" component={EntidadeCadastro} />
      <PrivateRoute path="/entidades_view" component={EntidadeView} />
      
      <PrivateRoute path="/usuarios" component={UsuarioConsulta} />
      <PrivateRoute path="/usuarios_cad" component={UsuarioCadastro} />      
      <PrivateRoute path="/usuarios_view" component={UsuarioView} />      
      
      <PrivateRoute path="/atos" component={AtoConsulta} />
      <PrivateRoute path="/ato_cad" component={AtoCadastro} />
      
      <PrivateRoute path="/tiposentidade" component={TipoEntidadeConsulta} />
      <PrivateRoute path="/tiposentidade_cad" component={TipoEntidadeCadastro} />

      <PrivateRoute path="/certificado" component={Certificado} />

      <PrivateRoute path="/capa" component={CapaCadastro} />

      <PrivateRoute path="/anuncio" component={AnuncioCadastro} />
      
      <PrivateRoute path="/alterasenha" component={AlteraSenha} />
      
      <Route exact path="/" component={Login} />      
      <Route exact path="/logout" component={PageSair} />      

      <Route path="/404" component={Page404} />      
      
      {/* <LoginRoute path="/:id?" component={Login} /> */}

    </Switch>
  </BrowserRouter>
);

export default Routes;
