import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
const date = new Date();

function NavBar({history}) {
  const [redirect, setRedirect] = useState(false);

  const [dateTime, setDateTime] = useState({
    hora: date.getHours(),
    minuto: date.getMinutes(),
  });

  useEffect(() => {
    const timer = setInterval(() => {
      const date = new Date();
      var dia = ('00' + date.getDate()).slice(-2);
      var mes = ('00' + (date.getMonth()+1)).slice(-2);
      var ano = date.getFullYear();
      setDateTime({
        hora: ('00' + date.getHours()).slice(-2),
        minuto: ('00' + date.getMinutes()).slice(-2),
        data:  dia + '/' + (mes) + '/' + ano
      });
    }, 1000);
    return () => clearInterval(timer);
  }, []);
  

  const sair = e => {
    e.preventDefault();
    setRedirect(true);
  }

  if (redirect)
  {
    return (
      <Redirect to="/logout/" />
    );
  }else{
    return (
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
          </li>        
        </ul>
    
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" href="#" >
            <b>Data:</b> {dateTime.data} <b>Hora:</b> {dateTime.hora}:{dateTime.minuto}
            </a>
          </li>
        </ul>

        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <a className="nav-link" href="#" onClick={sair} role="button">
              Sair <i className="fas fa-sign-out-alt"></i>
            </a>
          </li>
          {/* <li className="nav-item">
            <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button">
              <i className="fas fa-th-large"></i>
            </a>
          </li> */}
        </ul>
      </nav>
    );
  }
}

export default NavBar;