import React, {useState, useEffect} from 'react';
import {toast} from 'react-toastify';

import api from '../../services/api';

import { Container } from './styles';

function Page404({history, escola}) {
    const [codigo, setCodigo] = useState('');

    useEffect(() => {
        if (escola)
            history.push(`/${escola.cnpj}`);
            
    }, [])

    async function loadEscola(e) {
        e.preventDefault();
        
        const response = await api.get('escola', {
            params: { codigo },
        });
        
        if (response.data.cnpj)
            history.push(`/${response.data.cnpj}`);
        else{
            toast.error('Instituição não encontrada.');
        }
        // dispatch({ type: '@ESCOLA/SAVE', escola: response.data });

    }

    function handlechange(e){
        setCodigo(e.target.value);
    }

    return (
        <Container>
            <div className="error-wrapper">
                <div className="man-icon"></div>
                <h3 className="title">404</h3>
                <p className="info">Oh! Pagina não encontrada</p>                
            </div>
        </Container>
    );
}

export default Page404;