import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import pt from 'date-fns/locale/pt-BR';
import {format, startOfDay, isBefore, startOfToday} from 'date-fns';
import * as $ from 'jquery';
import { Link } from 'react-router-dom';

import SideBar from '../../components/SideBar';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import api from '../../services/api';
import config from '../../services/config';


function PublicacaoConsulta({history, usuario, location}) {
    
    const date = startOfDay(new Date());
    const [atos, setAtos] = useState(null);
    const [id_tipo_ato, setTipoAto] = useState('');
    const [entidades, setEntidades] = useState([]);
    const [id_entidade, setIdEntidade] = useState('');
    const [publicacao, setPublicacao] = useState(null);
    const [palavra_chave, setPalavraChave] = useState('');
    const [situacao, setSituacao] = useState('');
    const [status, setStatus] = useState('');
    const [data_cadastro, setDataCadastro] = useState(format(startOfToday(date), 'yyyy-MM-dd'));
    const [data_publicacao, setDataPublicacao] = useState('');
    const [lista, setLista] = useState(null);
    const [desstatus, setDesStatus] = useState('');
    const [novaSituacao, setNovaSituacao] = useState('1');
    const [justificativa, setJustificativa] = useState('');

    const [filtro, setFiltro] = useState({
        data_cadastro: format(startOfToday(date), 'yyyy-MM-dd'),
        dataCadastroPick: date,
        data_publicacao: '',
        palavra_chave: '',
        id_tipo_ato: '',
        id_entidade: usuario.id_entidade,
        situacao: '',
        status: '0'
    })

    // eslint-disable-next-line
    const [dataCadastroPick, setDataCadastroPick] = useState(date);
    // eslint-disable-next-line
    const [dataPublicacaoPick, setDataPublicacaoPick] = useState(null);
  
    function inputCadastroChange(e){
        setDataCadastroPick(e);
        setFiltro({...filtro, data_cadastro: e, dataCadastroPick: e})
        // setDataCadastro(e);
    }
    function limpaDataCadastro(e){
        setDataCadastroPick('');
        setFiltro({...filtro, data_cadastro: '', dataCadastroPick: ''})
        // setDataCadastro('');
    }

    function inputPublicacaoChange(e){
        setDataPublicacaoPick(e);
        setFiltro({...filtro, data_publicacao: e, dataPublicacaoPick: e})
        // setDataPublicacao(e);
    }
    function limpaDataPublicacao(e){
        setDataPublicacaoPick('');
        setFiltro({...filtro, data_publicacao: '', dataPublicacaoPick: ''})
        // setDataPublicacao('');
    }

    function toggleDrop(e) {
        e.preventDefault();
        // $(e.target.id).dropdown('toggle');
        return false;
    }

    useEffect(() => {
        async function carregaTabela(){

            let filtro_ = {...filtro};
            if (location.state)
            {
                filtro_ = location.state.filtro;
                setFiltro(location.state.filtro);
            }

            const result = await api.get('ato');
            setAtos(result.data);
            
            const resultPublic = await api.get('/publicacoes', {params: filtro_});    

            const filtro_ent = {
                nome: '',        
                sigla: '',
                nome_entidade_pai: '',
                id_municipio: '',
                id_tipo_entidade: '',
                status: '',
            };
            const resultEntidades = await api.get('/entidade', {params : filtro_ent});

            setEntidades(resultEntidades.data ? resultEntidades.data : []);

            setLista(resultPublic.data ? resultPublic.data : null);
        }

        
        
        carregaTabela();
    }, [])

    const consutlar = async (e) => {
        e.preventDefault();
        const result = await api.get('/publicacoes', {params : filtro});
        
        setLista(result.data ? result.data : null);
    }
        
    function viewItem(e, item){
        e.preventDefault();
        history.push('/publicacoes_view', {publicacao: item, filtro});
    }

    function editItem(e, item){
        e.preventDefault();
        history.push('/publicacoes_cad', {publicacao: item});
    }

    function aprovar(e, item){
        e.preventDefault();
        
        // console.log(item);
        // console.log(usuario);

        setPublicacao(item);
        window.$('#modalPublicacao').modal('toggle');
    }

    async function confirmStatus(){
        const novostatus = publicacao.status == 1 ? 0 : 1;
        const desstatus = novostatus == 1 ? 'Excluído' : 'Ativado';

        const del = await api.put('/publicacao/status', {id_publicacao: publicacao.id_publicacao, status: novostatus});

        if (del.data)
        {
            toast.success(`Publicação ${desstatus} com sucesso`);
        }

        const result = await api.get('/publicacoes', {params: filtro});
        setLista(result.data);
        window.$('#modalConfirm').modal('toggle');

    }

    function statusSwitch(publicacao){
        console.log(publicacao);
        const novostatus = publicacao.status == 1 ? 0 : 1;
        const desstatus = novostatus == '1' ? 'EXCLUIR' : 'ATIVAR';
        setDesStatus(desstatus);
        setPublicacao(publicacao);
    }

    // GRAVAR APROVAÇÃO
    async function gravar(e){
        e.preventDefault();

        
        const del = await api.put('/publicacao/status', {
            id_publicacao: publicacao.id_publicacao, 
            status: publicacao.status, 
            justificativa,
            situacao: novaSituacao
        });
        console.log(del.data);
        if (del.data)
        {
            toast.success(`Publicação ${novaSituacao == '1' ? 'Aprovada' : 'enviada para revisão'} com sucesso`);
        }

        const result = await api.get('/publicacoes', {params :{
            data_cadastro,
            data_publicacao,
            palavra_chave,
            id_tipo_ato,
            id_entidade,
            situacao,
        }});
        setLista(result.data);

        if (novaSituacao > 1)
        {
            const sendEmail = await api.post('http://diariooficial.tecnologia.ws/send.php', {
                data_cadastro: publicacao.dt_cadastro, 
                email: publicacao.email_usuario, 
                nome: publicacao.nome_usuario, 
                justificativa,
                tipo_ato: publicacao.ato,
                titulo: publicacao.titulo,
            });
            console.log(sendEmail);
        }
        window.$('#modalPublicacao').modal('hide');
        
        setJustificativa('');
        setNovaSituacao(1);
        // history.replace('/publicacoes');

    }

    function abrirJustificativa(e, justificativa){
        e.preventDefault();
        setJustificativa(justificativa);
        window.$('#modalJustificativa').modal('toggle');
    }

    return (
        <>
            <NavBar history={history} />
            <SideBar opcao="publicacoes"/>
            <div className="content-wrapper ">            
                <section className="content main-content">
                    <div className="container-fluid">
                        <br />
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">Publicações</li>
                            </ol>
                        </nav>
                        <div className="card">
                            <div className="card-body">

                                <form className={config.isMobile ? '' : ''}>
                                    <div className="form-row">  
                                        <div className="form-group col-md-2 ">
                                            <label>Data do Cadastro</label>
                                            <DatePicker
                                                name="data"
                                                dateFormat="dd/MM/yyyy"                                                
                                                selected={filtro.dataCadastroPick}
                                                onChange={inputCadastroChange}
                                                onClickOutside={limpaDataCadastro}
                                                className="form-control"
                                                // customInput={<CustomDatePciker />}
                                                locale={pt} />
                                        </div>
                                        <div className={'form-group col-md-2 ' + (config.isMobile ? 'mb-2' : '')}>
                                            <label>Data da Publicação</label>
                                            <DatePicker
                                                name="data"
                                                dateFormat="dd/MM/yyyy"
                                                selected={filtro.dataPublicacaoPick}
                                                onChange={inputPublicacaoChange}
                                                onClickOutside={limpaDataPublicacao}
                                                className="form-control"
                                                // customInput={<CustomDatePciker />}
                                                locale={pt} />
                                        </div>
                                        <div className={'form-group col-md-3 ' + (config.isMobile ? 'mb-2' : '')}>
                                            <label>Palavra Chave</label>
                                            <input type="text" className="form-control" placeholder="" 
                                            value={filtro.palavra_chave} onChange={e => setFiltro({...filtro, palavra_chave: e.target.value})} />
                                        </div>
                                        <div className={'form-group col-md-3 ' + (config.isMobile ? 'mb-2' : '')}>
                                            <label>Tipo do ATO</label>
                                            <select className="form-control" onChange={e => setFiltro({...filtro, id_tipo_ato: e.target.value})} value={filtro.id_tipo_ato}>
                                                <option value="">...</option>
                                                {atos && atos.map((item, index) => 
                                                    <option key={index} value={item.id_tipo_ato}>{item.nome}</option>
                                                )}
                                            </select>
                                        </div>
                                        
                                    </div>
                                    <div className="form-row">  
                                        <div className="form-group col-md-5">
                                            <label>Entidade</label>
                                            <select className="form-control" disabled={'1,2'.indexOf(usuario.id_perfil) === -1} onChange={e => setFiltro({...filtro, id_entidade: e.target.value})} value={filtro.id_entidade}>
                                                <option value="">Todos</option>
                                                {entidades && entidades.map(item => (
                                                    <option key={item.id_entidade} value={item.id_entidade}>{item.nome}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label>Situação</label>
                                            <select className="form-control" onChange={e => setFiltro({...filtro, situacao: e.target.value})} value={filtro.situacao}>
                                                <option value="">Todos</option>
                                                <option value="0">Aguardando Aprovação</option>
                                                <option value="1">Aprovado</option>
                                                <option value="2">Revisar</option>
                                            </select>
                                        </div>     
                                        {/* <div className="form-group col-md-3">
                                            <label>Status</label>
                                            <select className="form-control" onChange={e => setFiltro({...filtro, status: e.target.value})} value={filtro.status}>
                                                <option value="">Todos</option>
                                                <option value="0">Ativa</option>
                                                <option value="1">Desativada</option>
                                            </select>
                                        </div>                                    */}
                                    </div>
                                    <div className="form-row">
                                        <div className={'form-group col'}>
                                            <button className="btn btn-primary col-md-3 col-sm-6" onClick={consutlar}>Consultar</button>
                                        </div>
                                        <div className={'form-group col text-right'}>
                                            <Link className="btn btn-success col-md-3 col-sm-6" to="/publicacoes_cad">Novo</Link>
                                        </div>
                                    </div>
                                </form>
                            
                                <hr />

                                <table className="table table-striped">
                                   
                                    <thead >
                                        <tr>
                                        <th style={{width: 110}} >DATA CADASTRO</th>
                                        <th >TITULO</th>
                                        <th >ENTIDADE</th>
                                        <th style={{width: 110}} >DATA PUBLICAÇÃO</th>
                                        <th style={{width: 100}} >SITUAÇÃO</th>
                                        {/* <th style={{width: 60}} >STATUS</th> */}
                                        <th style={{width: 50}}>OPÇÕES</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {lista ? lista.map((item, index) => (
                                            // <tr key={index} style={{background:  '0,2'.indexOf(item.situacao) > 0 && '#FFD700',}}>
                                            <tr key={index}>
                                                <th style={{width: 110}} className="text-center">{item.dt_cadastro}<br />{item.hr_cadastro}</th>
                                                <td className="align-middle">{item.titulo}</td>
                                                <td className="align-middle">{item.nome_entidade}</td>
                                                <td className="text-center align-middle">{item.dt_publicacao}</td>
                                                <td className="align-middle">
                                                    {item.situacao_des} 
                                                
                                                    {'0,2'.indexOf(item.situacao) > 0 && (
                                                        <Link to="#"  onClick={(e) => abrirJustificativa(e, item.justificativa)} >
                                                            <span style={{marginLeft: 6}} className="fas fa-exclamation-circle icon-alert"></span>
                                                        </Link>
                                                    )}
                                                
                                                </td>
                                                {/* <td className="align-middle">
                                                    {item.status == '0' ? 'Ativa' : 'Desativada'} 
                                                </td> */}
                                                <td className="text-center" style={{width: 80}}>
                                                    <div className="dropdown dropleft">
                                                        <button onClick={toggleDrop} id="dropMenu" className="btn btn-secondary btn-sm" type="button"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="nav-icon fas fa-list"></i>
                                                        </button>

                                                        <div className="dropdown-menu" aria-labelledby="dropMenu">
                                                            <Link className="dropdown-item" to="#" onClick={(e) => viewItem(e, item)}>Detalhes</Link>
                                                            <a className="dropdown-item" target="_blank" href={config.baseURL+"print.php?id_publicacao=" + item.id_publicacao} >Imprimir</a>
                                                            {('0,2'.indexOf(item.situacao) > -1 || usuario.id_perfil == '1') ? (
                                                                <Link className="dropdown-item" to="#" onClick={(e) => editItem(e, item)}>Editar </Link>
                                                            ) : (
                                                                <Link className="dropdown-item disabled" to="#">Editar </Link>
                                                            )}
                                                            {((item.situacao == '0') && '1,2,4'.indexOf(usuario.id_perfil) > -1) && (
                                                                <Link className="dropdown-item" to="#" onClick={(e) => aprovar(e, item)} data-toggle="modal" data-target="#modalPublicacao">Aprovar</Link>
                                                            )}
                                                            {!item.id_diario && ('1,2'.indexOf(usuario.id_perfil) > -1) && (
                                                                <Link className="dropdown-item" to="#" onClick={() => statusSwitch(item)} data-toggle="modal" data-target="#modalConfirm" >{'Excluir Publicação'}</Link>
                                                            )}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td colSpan={6}></td>
                                            </tr>
                                        )}
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div className="modal fade" id="modalConfirm" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            {publicacao && (
                                <strong>Gostaria de Excluir a Publicação "{publicacao.titulo}" ?</strong>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger col-md-3" onClick={confirmStatus} >Sim</button>
                            <button type="button" className="btn btn-outline-dark col-md-3" data-dismiss="modal">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal" tabIndex="-1" id="modalJustificativa">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body text-center">
                            <b >{justificativa}</b>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Fechar</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalPublicacao" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        {publicacao && (
                            <>
                                <div className="modal-header">
                                    <h5 className="modal-title">
                                        ID da Publicação: {publicacao.codigo_identificador}
                                    </h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <strong>{publicacao.titulo}</strong><br />
                                    <div className="" dangerouslySetInnerHTML={ { __html: publicacao.descricao}}>
                                    </div>
                                    <form className="">
                                        <div className="form-row">  
                                            <div className="form-group col-md-12">
                                                <label>Situação</label>
                                                <select className="form-control" onChange={e => setNovaSituacao(e.target.value)} value={novaSituacao}>
                                                    <option value="1">Aprovado</option>
                                                    <option value="2">Revisar</option>
                                                </select>
                                            </div>
                                        </div>
                                        {novaSituacao == '2' && (
                                        <div className="form-row">  
                                            <div className="form-group col-md-12">
                                                <label>Justificativa</label>
                                                <textarea className="form-control" value={justificativa} onChange={e => setJustificativa(e.target.value)} rows="3"/>
                                            </div>
                                        </div>
                                        )}
                                    </form>
                                </div>
                                
                                <div className="modal-footer">
                                    <button type="button" onClick={gravar} className="btn btn-outline-success col-md-3" data-dismiss="modal">Salvar</button>
                                    <button type="button" className="btn btn-outline-dark col-md-3" data-dismiss="modal">Cancelar</button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <aside className="control-sidebar control-sidebar-dark">
            </aside>
            <Footer />
        </>
    );
}

export default connect((state) => ({
    sistema: state.sistema,
    usuario: state.usuario
}))(PublicacaoConsulta);