import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: 0;
    font-size: 12px!important;
  }

  *:focus{
    outline: 0;
  }

  html, body {
    background: #FFF;    
    height: auto;
    min-height: 100%;
  }

  #root {  
    background-color: #FFF;
    height: 100vh;
    width: 100%;
    position: absolute;
  }

  body {
    background: #FFF;    
  }

  body, input, button {
    font: 14px 'Roboto', sans-serif;
  }

  .main-content{
    padding-bottom: 80px!important;
  }

  button {
    cursor: pointer;
  }

  .button-icon {
    cursor: pointer;
  }

  #carregando{
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    color: rgba(0, 0, 0, 0.8);
    width: 100%;
  }

  .loading-indicator {
    background: rgba(0,0,0,0.8);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999999;

    img {
      position: relative;
      left: 50%;
      top: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
    }
  }

  .content-wrapper {
    height: calc(100vh - 57px);    
  }
  .react-datepicker-wrapper {
    display: block!important;
  }

  .required {
    color: red;
  }

  .disabled{
    cursor: not-allowed!important;
    background: #ccc;
  }

  .menu-disabled{
    cursor: not-allowed!important;
  }

  .ck-editor__editable {
    height: 300px!important;
    max-height: 300px!important;
  }

  .tox-statusbar{
    display: none !important;
  }

  .padding-bottom {
    padding-bottom: 65px!important;
  }

  .tox-editor-header {
    position: sticky!important;
    display: contents;
  }

  .tabela {
    display: block;
    max-height: 220px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .fontcontador{
    font-size: 35px!important;
  }

  .fonttitulocontador{
    font-size: 16px!important;
    width: 110px;
  }

  .box-contador{
    height: 120px!important;
    margin-bottom: 0px!important;
    flex: 1;
    align-items: center;
    display: flex;
  }

  .small-box .icon>i.fa, .small-box .icon>i.fab, .small-box .icon>i.fad, .small-box .icon>i.fal, .small-box .icon>i.far, .small-box .icon>i.fas, .small-box .icon>i.ion{
    font-size: 20px!important;
    top: 10px!important;
  }

  .msg_inicial{
    font-size: 18px!important;
    font-family: sans-serif;
    font-weight: bold;
    padding: 5px;
    padding-top: 20px;

    strong {
      font-size: 21px!important;
      font-weight: 900;
      font-family: sans-serif;
    }
  }

  .table-diario td, .table-diario th {
    border-top: none!important;
  }

  .icon-alert {color: orange!important; font-size: 18px!important;}

  #treeview-diario.showme {
    display: block;
    opacity: 1;
    transition: all  0.8s linear;
    -webkit-transition: all 0.8s linear;
  }

  #treeview-diario {
    display: none;
    opacity: 0;
    transition: all  0.8s linear;
    -webkit-transition: all 0.8s linear;
  }

  #treeview-tabelas.showme {
    display: block;
    opacity: 1;
    transition: all  0.8s linear;
    -webkit-transition: all 0.8s linear;
  }

  #treeview-tabelas {
    display: none;
    opacity: 0;
    transition: all  0.8s linear;
    -webkit-transition: all 0.8s linear;
  }
`;
