import React, {useState, useEffect} from 'react';
import { Redirect } from 'react-router';

import { Container } from './styles';

function PageSair({history}) {
    const [sair, setSair] = useState(false);

    useEffect(() => {
        setTimeout(() => { 
            localStorage.removeItem('dom.usuario');
            setSair(true);
        }, 3000);
            
    }, [])

    if (sair)
    {
        return (
        <Redirect to="/" />
        );
    }else{
        
        return (
            <Container>
                <div className="error-wrapper">
                    <div className="man-icon"></div>
                    <h3 className="title">Efetuando Logoff</h3>
                    <p className="info">Aguarde!</p>                
                </div>
            </Container>
        );
    }
}

export default PageSair;